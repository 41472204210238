<template>
  <div v-if="user" class="grid gap-8">

    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.languageClasses') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" />
      </header>

      <div class="flex mb-4">
        <BccButton variant="primary" type="button" @click="registerParticipation">
          {{ t('languageClass.button') }}
        </BccButton>
      </div>

      <BccTable :columns="columns()" :items="sortedLanguageClasses"
        v-model:sortBy="store.sortBy" v-model:sortDirection="store.sortDirection" class="max-w-full overflow-x-auto">
        
        <template #item.actions="{ item }">
          <BccButton variant="tertiary" size="sm" :padding="false" :icon="ChevronRightIcon" iconRight
            @click="editLanguageClass(item)">{{ t('dashboard.actions.change') }}</BccButton>
        </template>

      </BccTable>

      <div v-if="store.isLoading" class="text-center w-full my-4">
        <BccButton>{{ t('loading') }}</BccButton>
      </div>

      <div v-if="!store.isLoading && !sortedLanguageClasses.length" class="m-4">
        <p>{{ t('dashboard.noLanguageClass') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { readItems } from '@directus/sdk';
import { useRouter } from 'vue-router'
import { getFormattedDate } from '../helpers/fields.js'
import { BccButton, BccInput, BccTable } from '@bcc-code/design-library-vue'
import { ChevronRightIcon } from '@bcc-code/icons-vue'

const router = useRouter()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = reactive({
  isLoading: true,
  languageClasses: [],
  search_name: '',
  sortBy: 'display.date',
  sortDirection: 'descending'
})

const columns = () => [
  {
    text: t('dashboard.date'),
    key: 'display.date'
  },
  {
    text: t('dashboard.name'),
    key: 'display.user'
  },
  {
    text: t('dashboard.participants'),
    key: 'display.participants'
  },
  {
    text: t('dashboard.actions.title'),
    key: "actions",
    sortable: false
  }
]

onBeforeMount(async () => {
  if (directusStore.userHasRole('applicant'))
    router.push({ path: '/' })

  await getLanguageClasses()
})

const sortedLanguageClasses = computed({
  get() {
    return store.languageClasses
      .filter(a => {
        return (a.display.user + ' ' + a.display.user).toLowerCase().includes(store.search_name.toLowerCase())
          || (a.display.user + ' ' + a.display.user).toLowerCase().includes(store.search_name.toLowerCase())
      })
  }
})

function transformApplicationsData(data) {
  return data
    .map(item => {
      const display = {}

      if (item.date) {
        display.date = getFormattedDate(item.date)
      }

      if (item.user_created) {
        display.user = `${item.user_created.first_name} ${item.user_created.last_name}`
      }

      if (item.participation) {
        const numberOfParticipants = item.participation.filter(a => a.participated).length
        display.participants = `${numberOfParticipants} ${numberOfParticipants == 1 ? t('dashboard.participant').toLowerCase() : t('dashboard.participants').toLowerCase()}`
      }

      return { ...item, display }
    })
}

async function getLanguageClasses() {
  const languageClasses = await directus.request(
    readItems('norwegian_class_participations', {
      limit: -1,
      fields: [
        'id', 'date', 'participation',
        'user_created.first_name', 'user_created.last_name'
      ]
    })
  )
  store.languageClasses = transformApplicationsData(languageClasses)

  store.isLoading = false
}

function editLanguageClass(language_class) {
  router.push({ path: '/language-classes/' + language_class.id })
}

function registerParticipation() {
  router.push({ path: '/language-classes/+' })
}

</script>
export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake"])},
  "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til dashboardet"])},
  "communication": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikasjon"])},
    "wrote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skrev"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])}
  },
  "dashboard": {
    "actions": {
      "assessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til språk evaluering"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre"])},
      "confidentialNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfidensielle merknader"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
      "editEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger evaluering"])},
      "editReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre referanse"])},
      "evaluateApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer søknad"])},
      "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluering"])},
      "finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullfør søknad"])},
      "goToIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til hendelse"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se gjennom søknaden din"])},
      "seeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se profil"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi tilbakemelding"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlinger"])}
    },
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alder"])},
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søknad"])},
    "application_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søknadsstatus"])},
    "arrival_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankomstdato"])},
    "ateamInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team intervju"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsdato"])},
    "clickHereForMoreInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trykk her for mer informasjon"])},
    "clickHereToScheduleInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trykk her for å avtale en time"])},
    "clickHereToUploadDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trykk her for å laste opp dine dokumenter"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "current_employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsgiver"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
    "departure_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avreisedato"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenter"])},
    "driver_license": {
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
      "started_will_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Før start"])}
    },
    "employment_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansettelsessluttdato"])},
    "employment_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansettelsesstartdato"])},
    "employmentStatus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansettelsesstatus"])}
    },
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluttdato"])},
    "filter": {
      "church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer på lokalmenighet"])},
      "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer på arbeidsgiver"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på navn ..."])},
      "yearGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer etter årskull"])}
    },
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her"])},
    "interviewStatus": {
      "interview_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervju gjennomført"])},
      "interview_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervju mangler"])},
      "interview_scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervju planlagt"])}
    },
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
    "levels": {
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nivå"])},
      "speak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snakke"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste nivå"])},
      "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forstå"])}
    },
    "local_church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalmenighet"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plassering"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "noApplicationAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen søknad tilgjengelig"])},
    "noChurchApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen søknad fra din menighet"])},
    "noIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hendelse"])},
    "noLanguageClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kurs registrert"])},
    "noParticipantInLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen deltaker med denne plassering"])},
    "noPersonalApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke sendt din søknad enda. Send den inn"])},
    "norwegianTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsklærer"])},
    "on_track": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I rute"])}
    },
    "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltaker"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere"])},
    "potential_placement": {
      "arrangement_ledelse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrangement / ledelse"])},
      "byggeplass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass"])},
      "byggeplass_germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass Tyskland"])},
      "byggeplass_norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass Norge"])},
      "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "carpenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømrer"])},
      "cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renhold"])},
      "communication_coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikasjon / koordinator"])},
      "drift_brunstad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drift Brunstad"])},
      "electrician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker"])},
      "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økonomi"])},
      "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyskland"])},
      "graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafisk design"])},
      "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoReCa"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IKT"])},
      "lagerjobb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerjobb"])},
      "llb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLB"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketsføring"])},
      "media_and_redaksjon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media & Redaksjon"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikk"])},
      "north_germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nord Tyskland"])},
      "plumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rørlegger"])},
      "south_germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sør Tyskland"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulig plassering"])},
      "work_assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjelpearbeider"])},
      "yep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YEP"])}
    },
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremgang"])}
    },
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "reviewOrUpdateApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se på eller oppdater din søknad"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppstartdato"])},
    "status": {
      "active_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåværende deltakelse"])},
      "application_withdrawn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søknad trukket tilbake"])},
      "completed_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluttet deltakelse"])},
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fikk ", _interpolate(_named("count")), " tilbakemelding"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
      "needs_clarification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenger avklaring"])},
      "needs_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenger gjennomgang"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
      "placement_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plassering bekreftet"])},
      "ready_for_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar for intervju"])},
      "ready_for_placement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar for plassering"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter på tilbakemelding"])},
      "waiting_for_clarification_local_church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter på avklaring - lokalmenighet"])},
      "waiting_for_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter på referanse"])},
      "waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venteliste"])}
    },
    "statusAndTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status og oppgaver"])},
    "wntInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WnT intervju"])}
  },
  "employment": {
    "backToParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til deltaker"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger ansettelsesdetaljer"])}
  },
  "finance": {
    "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontodetaljer"])},
    "accountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil betale fra en norsk bankkonto, gjør en bankoverføring til kontonummeret nedenfor. Ellers, klikk på «Betal nå» knappen ovenfor."])},
    "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontonummer"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "cancelledPayment": {
      "goToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til «Min økonomi» for å fortsette med betalingen."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har kansellert betalingen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansellert betaling"])}
    },
    "cancelledSubscription": {
      "goToSubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til «Min økonomi» for å starte på nytt."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har kansellert månedlig abonneringen!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansellert abonnering"])}
    },
    "customAmountPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinert beløp"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innskudd"])},
    "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inntil videre blir alle fremtidige statuser kommunisert via e-post. Du vil motta en månedlig faktura i slutten av hver måned med forfall den 20. påfølgende måned. Hvis du har spørsmål om fakturaen, kan du kontakte ", _interpolate(_named("email")), "."])},
    "monthlySubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig abonnement"])},
    "noBudget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen budsjett satt opp for denne deltaker."])},
    "noPrePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen innskuddsbetaling satt opp for denne deltaker."])},
    "noStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen status for denne konto tilgjengelig."])},
    "notSubscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke abonnert"])},
    "oneTimePayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engangsbetalinger"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal nå"])},
    "services": {
      "Buk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp/stevne"])},
      "Kontingent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontingent"])}
    },
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt"])},
      "partially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis betalt"])},
      "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlagt"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter betaling"])}
    },
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnere"])},
    "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnert"])},
    "successfulPayment": {
      "questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ved spørsmål, ta gjerne kontakt på e-post: ", _interpolate(_named("email")), "."])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk for betalingen!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket betaling"])}
    },
    "successfulSubscription": {
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har opprettet en månedlig abonnement!"])},
      "questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ved spørsmål, ta gjerne kontakt på e-post: ", _interpolate(_named("email")), "."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket abonnering"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økonomi"])}
  },
  "form": {
    "additional_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilleggskommentar"])},
    "address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gatenavn og -nummer"])}
    },
    "application_clip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søknadsklipp"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta opp en 1 - 2 minutter søknadsclip og fortell oss hvorfor du vil melde deg inn til A-team."])}
    },
    "areasOfKnowledge": {
      "carpenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snekker"])},
      "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bygg"])},
      "deco_graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deko / grafisk design"])},
      "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økonomi"])},
      "electrician_automatics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker - automatiker"])},
      "electrician_high_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker - sterkstrøm"])},
      "electrician_low_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker - svakstrøm"])},
      "event_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrangement ledelse"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IKT"])},
      "lumberjacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømmere"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketsføring"])},
      "mechanic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mekaniker"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikk"])},
      "online_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettbasert marketsføring"])},
      "plumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rørlegger"])},
      "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknologi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På hvilke områder har du profesjonell erfaring? (minst 1 år fastansettelse)"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan velge flere områder."])}
    },
    "birth_date": {
      "ageWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du er dessverre ikke gammel nok. Innen oppstarten må du ha fylt 18 år (1. august ", _interpolate(_named("nextProgramStartYear")), ").\nLes <a href=\"", _interpolate(_named("faqUrl")), "\" class=\"underline\">FAQ - Hvorfor må jeg fylle 18 år for å delta på A-team?</a> Du kan også gjerne ta kontakt på <a href=\"mailto:", _interpolate(_named("email")), "\" class=\"underline\">", _interpolate(_named("email")), "</a> for mer informasjon."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsdato"])}
    },
    "birth_place": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødested"])}
    },
    "can_work_full_time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg kan jobbe i en fulltidsstilling (100%) i et helt år"])}
    },
    "certificates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikater (personalledelse, Excel-kurs, gaffeltrucklisens)"])}
    },
    "chooseCurrentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg dagens dato"])},
    "citizenship": {
      "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
      "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
      "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algerie"])},
      "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
      "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
      "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
      "antigua_and_barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua og Barbuda"])},
      "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
      "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
      "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
      "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
      "australian_oceania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australske Oseania"])},
      "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østerrike"])},
      "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aserbajdsjan"])},
      "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
      "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
      "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
      "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
      "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus (Hviterussland)"])},
      "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgia"])},
      "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
      "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
      "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
      "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
      "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
      "bosnia_and_herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia og Herzegovina"])},
      "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botsuana"])},
      "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasil"])},
      "british_indian_ocean_territory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Indian Ocean Territory"])},
      "british_virgin_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De britiske jomfruøyene"])},
      "brunei_darussalam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei Darussalam"])},
      "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
      "burkina_faso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
      "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
      "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kambodsja"])},
      "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
      "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "canary_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanariøyene"])},
      "cape_verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kap Verde"])},
      "cayman_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caymanøyene"])},
      "central_african_republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den sentralafrikanske republikk"])},
      "ceuta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceuta"])},
      "chad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjad"])},
      "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
      "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kina"])},
      "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
      "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komorene"])},
      "costa_rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
      "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatia"])},
      "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
      "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kypros"])},
      "czech_republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjekkisk Republikk"])},
      "democratic_peoples_republic_of_korea_north_korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den demokratiske olkerepublikken Korea (Nord-Korea)"])},
      "democratic_republic_of_the_congo_zaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den demokratiske republikken Kongo (Zaire)"])},
      "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danmark"])},
      "djibouti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
      "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
      "dominican_republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den dominikanske republikk"])},
      "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
      "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
      "el_salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
      "equatorial_guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvatorial-Guinea"])},
      "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
      "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "etiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopia"])},
      "falkland_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandsøyene"])},
      "faroe_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færøyene"])},
      "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
      "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
      "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankrike"])},
      "french_polynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransk Polynesia"])},
      "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabun"])},
      "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
      "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
      "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyskland"])},
      "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
      "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
      "great_britain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storbritannia"])},
      "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hellas"])},
      "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grønland"])},
      "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
      "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
      "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
      "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
      "guinea_bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
      "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
      "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
      "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
      "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungarn"])},
      "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Island"])},
      "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
      "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
      "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
      "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
      "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irland"])},
      "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
      "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
      "ivory_coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfenbenskysten"])},
      "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
      "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
      "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordania"])},
      "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasakhstan"])},
      "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenia"])},
      "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
      "kosovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
      "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
      "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirgisistan"])},
      "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
      "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
      "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libanon"])},
      "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
      "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
      "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
      "liechtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litauen"])},
      "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
      "macau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
      "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makedonia"])},
      "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
      "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
      "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
      "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldivene"])},
      "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
      "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "marshall_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshalløyene"])},
      "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
      "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
      "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
      "melilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melilla"])},
      "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
      "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
      "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
      "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
      "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
      "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
      "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marokko"])},
      "mozambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mosambik"])},
      "myanmar_burma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Burma)"])},
      "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
      "nauru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
      "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
      "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
      "netherlands_antilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nederlandske Antillene"])},
      "new_caledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny Caledonia"])},
      "new_zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
      "new_zealand_oceania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand Oseania"])},
      "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
      "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
      "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
      "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norge"])},
      "nothern_mariana_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nord-Marianene"])},
      "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
      "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
      "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
      "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
      "papua_new_guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Ny-Guinea"])},
      "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
      "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
      "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filippinene"])},
      "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
      "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
      "polar_regions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polare regioner"])},
      "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
      "republic_of_korea_south_korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republikken Korea (Sør-Korea)"])},
      "republic_of_the_congo_brazzaville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republikken Kongo (Brazzaville)"])},
      "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
      "russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russland"])},
      "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
      "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
      "san_marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
      "sao_tome_and_principe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome og Principe"])},
      "saudi_arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi-Arabia"])},
      "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
      "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
      "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychellene"])},
      "sierra_leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
      "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
      "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
      "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
      "solomon_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon øyene"])},
      "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
      "south_africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sør-Afrika"])},
      "south_sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sør-Sudan"])},
      "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spania"])},
      "sri_lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
      "st_helena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Helena"])},
      "st_kitts_and_nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Kitts og Nevis"])},
      "st_lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Lucia"])},
      "st_pierre_and_miquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Pierre og Miquelon"])},
      "st_vincent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Vincent"])},
      "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
      "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
      "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
      "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sverige"])},
      "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sveits"])},
      "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
      "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
      "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadsjikistan"])},
      "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tansania"])},
      "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statsborgerskap"])},
      "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
      "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi oss beskjed hvis du ikke er europeisk, men har muligheten til å søke om europeisk statsborgerskap."])},
      "trinidad_and_tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad og Tobago"])},
      "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
      "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyrkia"])},
      "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
      "turks_and_caicos_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks- og Caicosøyene"])},
      "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
      "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
      "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraina"])},
      "united_arab_emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De forente arabiske emirater"])},
      "united_states_of_america": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerikas forente stater (USA)"])},
      "united_states_of_micronesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De forente stater i Mikronesia"])},
      "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
      "us_virgin_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De amerikanske jomfruøyene"])},
      "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
      "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
      "vatican": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikanet"])},
      "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
      "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
      "wallis_and_futuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis og Futuna"])},
      "west_bank_gaza_strip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestbredden / Gazastripen"])},
      "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemen"])},
      "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
      "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
    },
    "citizenshipPass": {
      "have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har allerede pass"])},
      "will_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skal søke"])},
      "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har søkt"])},
      "will_get_after_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Får etter oppstart"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen status"])}
    },
    "city": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sted"])}
    },
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukk"])},
    "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikasjon"])},
    "completed_learning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullført lære"])}
    },
    "completedStudy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullført studium"])},
      "too_young": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For ung"])},
      "will_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ved programstart"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
    },
    "completedVocationalTraining": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullført yrkesopplæring"])},
      "will_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ved programstart"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
    },
    "confirmAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreft og fortsett"])},
    "confirmNewDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk \"Lagre\" for å starte opplastingen av de nye dokumentene til søknaden din."])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke"])},
    "continueToWorkInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk her for å lagre fremdriften din og bekrefte at du ønsker å fortsette søknaden din med neste del som kommer fra WorkNTravel."])},
    "country_code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landskode"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst skriv inn telefonnummeret i riktig format: +49123456789"])}
    },
    "country": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
    },
    "criminal_record_check": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiattest"])}
    },
    "cv": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV"])}
    },
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Informasjon du oppgir vil bli brukt for å behandle søknaden. I den forbindelse kan det hende at vi deler informasjon du oppgir her med din nåværende ungdomsleder og fremtidige stedsansvarlig. Vi gleder oss å bli kjent med deg.\n\nFor mer informasjon om programmet, les dette: <a href=\"https://my.ateam.bcc.no/info/generell-info/\" class=\"underline\" target=\"_blank\">Generell informasjon om programmet</a>\n\nGodt å vite:<ul><li>Du kan starte søknaden nå og fullføre den senere ved å velge \"Lagre utkast\"</li><li>Felt merket med * er obligatoriske - noen dokumenter (f.eks. politiattest) kan legges til i søknaden i etterkant.</li><li>Alle må være på A1-nivå i norsk når man søker for å kunne nå det nødvendige nivået (A2/B1) innen programstart. Hvis du ikke har begynt å studere, kan du gjennomføre følgende kurs før den første prøven i slutten av august: <a href=\"https://t.me/+jbWsMn5oTXBlMmVk\" target=\"_blank\" class=\"underline\">A1 intensivkurs</a></li><li>Husk å fullføre søknad og velge \"«Send»\" innen fristen!</li><li>Ved spørsmål ta kontakt med vår Helpdesk:<ul><li>E-post: ", _interpolate(_named("email")), "</li><li>Telegram: <a href=\"https://t.me/Ateaminntak\" target=\"_blank\">https://t.me/Ateaminntak</a></li></ul></li></ul>"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenter"])},
    "documents_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følgende filformater kan brukes: Word (doc, docx), PDF, JPG, PNG, GIF. Et enkelt dokument kan ikke overstige 15 MB. Ikke last opp PDF- og Word-dokumenter som er passord- eller skrivebeskyttet."])},
    "dont_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vet ikke"])},
    "driver_license_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du noen kommentarer i forbindelse med førerkørtet?"])}
    },
    "driver_license_copy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Førerkort"])}
    },
    "driver_license_since": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor lenge har du hatt førerkortet (bilen)?"])}
    },
    "driverLicense": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har ikke og kommer ikke til å ha førerkort"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "started_will_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg forventer å ha førerkort før oppstart"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du førerkort?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har førerkort nå"])}
    },
    "driverLicenseClasses": {
      "excavator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravemaskin"])},
      "forklift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaffeltruck"])},
      "passenger_car_automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personbil - automatisk"])},
      "passenger_car_gear_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personbil - manuell gir"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilke førerkortklasser har du?"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk av for alle kjøretøyene du er registrert for."])},
      "tractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traktor"])},
      "trailer_drivers_license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailer førerkort"])},
      "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastebil"])}
    },
    "driving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Førerbevis"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utdanning"])},
    "educationStatus": {
      "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har ikke begynt"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begynt og ikke fullført"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "will_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skal fullføre før programstart"])}
    },
    "email_address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])}
    },
    "emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nødkontakt"])},
    "english_language_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engelsk"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk, engelsk og tysk er for tiden relevante språk for alle våre samarbeidspartnere. Derfor trenger vi kunnskapsnivået ditt for hvert av de tre språkene."])}
    },
    "errors": {
      "applicationUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en feil ved lagringen av søknaden din."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig epostadresse."])},
      "mustBeADateInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdien må være i dato format (YYYY-MM-DD). Gjerne bruk datovelger vinduet."])},
      "participationSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en feil ved lagringen av deltakelsen din."])},
      "referenceUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en feil ved lagringen av referansen din."])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er påkrevet."])},
      "submissionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en feil ved innsendingen av søknaden din."])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke godtatt elektronisk lagring av opplysninger."])},
      "uploadError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det har oppstått en feil med filen <strong>", _interpolate(_named("file")), "</strong> og den kunne ikke ha blitt lastet opp."])}
    },
    "european_health_insurance_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europeisk helsetrygdkort"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du har et europeisk forsikringskort, kan du laste det opp. Du finner den på baksiden av det vanlige forsikringskortet."])}
    },
    "event_participation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg deltar"])},
      "physically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fysisk"])},
      "digitally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalt"])},
      "cannot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke delta hverken fysisk eller digital"])},
      "dont_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vet ikke"])}
    },
    "first_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst skriv inn ditt fulle navn slik det er på identitetskortet ditt."])}
    },
    "food_intolerance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du noen matallergier?"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du har matintoleranse, vennligst skriv den ned her."])}
    },
    "former_participant": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du vært på A-Team før?"])}
    },
    "former_participation_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fra år til år"])}
    },
    "free_time_activities": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva liker du å gjøre på fritiden?"])}
    },
    "full_time_job": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du vært ansatt i en full stilling? (minst 1 år)"])}
    },
    "gender": {
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvinne"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mann"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjønn"])}
    },
    "general_info": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for at riktig e-post og telefonnummer er oppgitt her. Du vil motta neste henvendelse via epost."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generell informasjon"])}
    },
    "german_language_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tysk"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk, engelsk og tysk er for tiden primært relevante for våre samarbeidspartnere. Derfor trenger vi kunnskapsnivået ditt for hvert av de tre språkene."])}
    },
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helseinformasjon"])},
    "health_information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe helseinformasjon du ønsker å dele som kan være viktig med tanke på type arbeidstilbud du får? Noe du ønsker din stedsansvarlig skal vite om?"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil fortelle oss noe om helsetilstanden din, slik at vi bedre kan tilpasse arbeidsoppgavene, om nødvendig, kan du skrive det her."])}
    },
    "healthy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er sunn nok til å jobbe"])}
    },
    "hobbies": {
      "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matlaging"])},
      "esports_gaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Sport / Gaming"])},
      "football": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotball"])},
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
      "graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafisk design"])},
      "ice_hockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishockey"])},
      "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fritidsinteresser"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi vil gjerne vite hva dine fritidsinteresser er."])},
      "volleyball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball"])}
    },
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn svaret"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsikring"])},
    "international_experience_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du en kommentar til jobben i utlandet?"])}
    },
    "international_experience_longer_than_3_months": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du jobbet i et annet land lenger enn 3 måneder?"])}
    },
    "international_insurance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internasjonal forsikring"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du ikke har en internasjonal forsikring ennå, kan du søke om en og sende inn dokumentet senere."])}
    },
    "knowledgeExperienceAreas": {
      "accounting_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regnskap"])},
      "animation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
      "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Område"])},
      "automatician_low_voltage_fitter_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiker / Svakstrømsmontør"])},
      "cabinet_maker_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Møbelsnekker"])},
      "car_detailing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilklargjøring"])},
      "carpenter_woodworking_assistant_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snekker / Tømrer hjelpearbeider"])},
      "carpenter_woodworking_with_trade_certificate_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snekker / Tømrer med fagbrev"])},
      "choose_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg område ..."])},
      "cleaning_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rengjøring"])},
      "composing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komponering av musikk"])},
      "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bygg"])},
      "construction_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass"])},
      "construction_installation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montør bygg (type glassrekkverk eller pergola)"])},
      "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat"])},
      "cooking_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matlaging for mer enn 10 personer"])},
      "deco_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekor"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg alle der du har minst ett års erfaring."])},
      "design_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
      "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
      "electrician_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker"])},
      "electronic_music_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektromusikk / Proback"])},
      "event_arrangement_leadership_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events / Arrangement ledelse"])},
      "film_editing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmredigering"])},
      "film_production_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmproduksjon"])},
      "first_aid_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Førstehjelp"])},
      "game_development_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spill utvikling"])},
      "glass_railing_installation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montør glassrekkverk"])},
      "graphics_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilde"])},
      "groundwork_landscaping_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunnarbeid / landskapsarbeid"])},
      "helpdesk_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
      "industrial_electricity_textile_production_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrisøm / Tekstilproduksjon"])},
      "instruments_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spill 1 eller flere instrumenter"])},
      "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har lyst til å utvikle meg videre"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IKT"])},
      "kindergarten_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barnehage"])},
      "levels": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen eller veldig lite"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litt erfaring men har interesse"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har gjennomført vellykkede prosjekter / fremføringer"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har studert (også selvstudie) og jobber med det regelmessig (også dugnad)"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonell erfaring eller har hatt jobb"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivålegende"])}
      },
      "light_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lys"])},
      "llb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLB"])},
      "logistics_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistikk"])},
      "machine_operator_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskinfører anlegg"])},
      "media_film": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media og Film"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikk"])},
      "office_administration_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontor / Administrasjon"])},
      "office_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontor"])},
      "other_it_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øvrige IKT"])},
      "other_media_film_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øvrige media/film"])},
      "other_music_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øvrige musikk"])},
      "painting_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maling"])},
      "plumbing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rørlegger"])},
      "production_of_bakery_products_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produksjon av bakervarer"])},
      "pruefservice_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfservice"])},
      "skilled_worker_concrete_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fagarbeider betong"])},
      "software_development_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System utvikling"])},
      "sound_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyd"])},
      "stocktaking_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varetelling"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over hvor mange år du har erfaring i et område (inkl. dugnadserfaring)?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På hvilke områder har du erfaring?"])},
      "ux_designer_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX-designer"])},
      "ventilation_installation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montør ventilasjon"])},
      "waiter_catering_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servitør / catering"])},
      "warehouse_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerarbeid"])},
      "websites_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettsider"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
    },
    "languageLevels": {
      "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bra"])},
      "little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litt"])},
      "mother_tongue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morsmål"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingenting"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "very_well": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldig bra"])}
    },
    "languages": {
      "african_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrikanske Språk"])},
      "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisk"])},
      "basque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baskisk"])},
      "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarsk"])},
      "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinesisk"])},
      "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjekkisk"])},
      "danish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dansk"])},
      "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlandsk"])},
      "finnish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finsk"])},
      "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransk"])},
      "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gresk"])},
      "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiensk"])},
      "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japansk"])},
      "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreansk"])},
      "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvisk"])},
      "lithuanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litauisk"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre"])},
      "persian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persisk"])},
      "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsk"])},
      "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugisisk"])},
      "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumensk"])},
      "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisk"])},
      "serbo_croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbokroatisk"])},
      "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakisk"])},
      "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spansk"])},
      "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svensk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre språk"])},
      "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyrkisk"])},
      "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamesisk"])}
    },
    "last_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst skriv inn ditt fulle navn slik det er på identitetskortet ditt."])}
    },
    "leadershipResponsibilities": {
      "more_than_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, for over 5 personer"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
      "takes_initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg tar initiativ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du erfaring med teamledelse?"])},
      "up_to_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, for 1-4 personer"])}
    },
    "lived_alone_shared_apartment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du noen gang bodd alene / i en delt leilighet?"])}
    },
    "localChurches": {
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lilienhof"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tønsberg"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urbana"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usselo"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vácduka"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valdivia"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vancouver"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nancy"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waldhausen"])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waltrop"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winnipeg"])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wien"])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wroclaw"])},
      "119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østfold"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecticut"])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre"])},
      "122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valdres"])},
      "147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delaware"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detroit"])},
      "165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paradera"])},
      "168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didcot"])},
      "170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steinseltz"])},
      "172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanghai"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drammen Sande"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eiker"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dürrmenz"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittewald"])},
      "250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graz"])},
      "267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shenzhen"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenland"])},
      "286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groningen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twente"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross-Godems"])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ozornoe"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallingdal"])},
      "327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crasna"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamar"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
      "345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasov"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exter"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harstad"])},
      "370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobozia Mare"])},
      "371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangalore"])},
      "373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
      "374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretoria"])},
      "376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alwaye"])},
      "377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallorca"])},
      "380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medias"])},
      "381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilo"])},
      "382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leon"])},
      "387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubai"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessenhöfe"])},
      "390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjud"])},
      "391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caragiale"])},
      "392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bucharest"])},
      "396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorohoi"])},
      "398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catania"])},
      "399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curico"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holstebro"])},
      "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofia"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horten"])},
      "424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaoundé"])},
      "427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ternopol"])},
      "428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beljaevka"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hønefoss"])},
      "430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakarpattia"])},
      "431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherkassy"])},
      "433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudora"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
      "443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langtore"])},
      "445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Douala"])},
      "448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istanbul"])},
      "450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa Regina"])},
      "451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
      "452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team"])},
      "455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arequipa"])},
      "459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunstad"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bafoussam"])},
      "478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
      "479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team Vanderbijlpark"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raumberg"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørlandet"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrkslätt"])},
      "505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svartskog"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["København"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahti"])},
      "528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West-Nederland"])},
      "529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelderland"])},
      "536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shillong"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linnenbach"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustroń"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maubach"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melbourne"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missouri"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molde"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måløy"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo Sarandi"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslo/Follo"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergen"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottawa"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paso Flores"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulda"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salem"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandefjord"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sardinia"])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seattle"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweiz"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Petersburg"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavanger"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mumbai"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stord"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sydney"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syracuse"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huntworth"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toronto"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalmenighet"])}
    },
    "locations": {
      "berlin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])},
      "duerrmenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dürrmenz"])},
      "exter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exter"])},
      "fulda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulda"])},
      "hamar_eiker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamar / Eiker"])},
      "hamburg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
      "hessenhoefe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessenhöfe"])},
      "lilienhof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lilienhof"])},
      "linnenbach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linnenbach"])},
      "maubach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maubach"])},
      "muenchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["München"])},
      "oslo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslo"])},
      "ready_for_any_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar for alle steder!"])},
      "waldhausen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waldhausen"])},
      "waltrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waltrop"])},
      "wien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wien"])}
    },
    "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding"])},
    "military_experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du vært i militæret?"])}
    },
    "military_remarks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du en kommentar til erfaringen du har fått i militæret?"])}
    },
    "motivation_and_expectation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv kort om hvorfor du ønsker å søke deg inn på A-team og hvilke forventninger du har."])}
    },
    "motivation_as_team_leader_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du noen kommentarer til om du er motivert for å være teamleder eller om du foretrekker å unngå det?"])}
    },
    "motivation_as_team_leader": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville en jobb som teamleder motivere deg?"])}
    },
    "msOfficeKnowledge": {
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viderekommen"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunnkunnskap"])},
      "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utmerket"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Office"])}
    },
    "needs_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønsker dialog (se nedenfor)"])},
    "needsTransportation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg trenger transport"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussen kjører fra Brunstad kl. 12:30 og tilbake ca. kl. 16:30."])}
    },
    "needsTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg trenger tolk"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
    "non_standard_placement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utplassering utenfor Europa"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasseringssteder utenfor Europa krever høyere eller ekstra utgifter som du selv må dekke (f.eks. reisen til utplasseringsstedet, ekstra forsikringer krevet av staten, ekstra reiser knyttet til innlevering av spesielle dokumenter). Dette gjelder særlig Canada. Ønsker du, i tillegg til standardplasseringene i Europa, å bli vurdert for plassering utenfor Europa?"])}
    },
    "norwegian_language_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk, engelsk og tysk er for tiden primært relevante for alle samarbeidspartnere. Derfor trenger vi kunnskapsnivået ditt for hvert av de tre språkene."])}
    },
    "norwegian_personal_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk personnummer"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet gjelder bare norske statsborgere eller andre som har bodd i Norge tidligere."])}
    },
    "other_knowledge": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilke andre kunnskaper har du? Beskriv."])}
    },
    "other_travel_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øvrige"])},
    "pants_size": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buksestørrelse"])}
    },
    "participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakelse"])},
    "passport_first_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn (som angitt i passet)"])}
    },
    "passport_id_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Må samsvare med informasjonen som du la inn tidligere under \"Pass\""])}
    },
    "passport_issue_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for utstedelse av pass"])}
    },
    "passport_issue_place": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass utstedelsessted"])}
    },
    "passport_last_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn (som angitt i passet)"])}
    },
    "passport_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passnummer"])}
    },
    "passport_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass status"])},
    "passport_valability": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass gyldig til"])}
    },
    "personal_description": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv deg selv kort som person. Hva er dine personlige egenskaper? Hva er dine styrker og svakheter?"])}
    },
    "personal_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deg"])},
    "phone_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil"])}
    },
    "portfolio": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenke til portfolio"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du eksempler på kunstverkene dine eller musikalske fremføringer tilgjengelig på nettet?"])}
    },
    "possible_start_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første mulige oppstart"])}
    },
    "postal_code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnr."])}
    },
    "preferable_tasks_in_local_church": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv hvilke oppgaver du har gjort i din lokalmenighet og eventuelle ting har du hatt ansvar for. (F.eks. AK, trening, jobbe med budsjett, spille i musikken)"])}
    },
    "preferred_location_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kan ikke love noe, men har du et ønske eller noe du vil si i forbindelse med plasseringen eller jobbtilbudet fra samarbeidspartneren?"])}
    },
    "preferred_location_first_priority": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønsket plassering, første prioritet"])}
    },
    "preferred_location_second_priority": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønsket plassering, andre prioritet"])}
    },
    "preferred_location_third_priority": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønsket plassering, tredje prioritet"])}
    },
    "preferredCommunicationLanguages": {
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engelsk"])},
      "norwegian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foretrukket kommunikasjonsspråk"])}
    },
    "primary_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primært statsborgerskap"])},
    "privateInsurance": {
      "foreign_health_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenlandsk helseforsikring"])},
      "legal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridisk beskyttelse"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen av disse"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilke av disse forsikringene har du allerede privat?"])},
      "travel_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiseforsikring"])}
    },
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "profile_picture": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portrett bilde"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansiktet må være avbildet rett forfra. Begge øynene skal være helt synlige. Blikket skal være rettet mot kameraets linse. Ingen gruppebilder. (Vær oppmerksom på at den kan bli videresendt til potensielle arbeidsgivere.)"])}
    },
    "programInsurance": {
      "foreign_health_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenlandsk helseforsikring"])},
      "legal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridisk beskyttelse"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg vil ikke søke om en slik forsikring, og jeg er klar over at dette er min personlige risiko"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilke av disse forsikringene skal du få når du begynner?"])},
      "travel_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiseforsikring"])}
    },
    "recommend_to_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbefale å vente et år"])},
    "relative_address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pårørende: Adresse (gate, husnummer, postnummer, by, land)"])}
    },
    "relative_country_code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pårørende: Landskode"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst skriv inn telefonnummeret i riktig format: +49123456789"])}
    },
    "relative_email_address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pårørende: Epostadresse"])}
    },
    "relative_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pårørende: Etternavn, fornavn"])}
    },
    "relative_phone_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pårørende: Mobiltelefonnummer"])}
    },
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern alle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre"])},
    "saveAndCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre og sjekk"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har lagret søknaden din! Du kan komme tilbake til den for å fullføre."])},
    "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre utkast"])},
    "school_level_on_start": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forventet høyeste gjennomførte utdanning ved programstart"])}
    },
    "school_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høyeste gjennomførte utdanning i dag"])}
    },
    "schoolLevels": {
      "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalifisert til inntak på høyskole"])},
      "high_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videregående skole"])},
      "no_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatorisk grunnskole"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høyeste gjennomførte utdanning"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalifisert til inntak på høyere utdanning"])}
    },
    "schoolMainActivities": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "odd_jobs_temporary_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd jobber / midlertidige jobber"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praksis"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skole"])},
      "solid_employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solid ansettelse"])},
      "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studium"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjeldende hovedaktivitet"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utdanning"])}
    },
    "secondary_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundært statsborgerskap"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg alle"])},
    "shoesSizes": {
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 35 / US 5 / UK 3"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 36 / US 6 / UK 4"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 37 / US 6.5 / UK 4.5"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 38 / US 7.5 / UK 5.5"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 39 / US 8 / UK 6"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 40 / US 9 / UK 7"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 41 / US 9.5 / UK 7.5"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 42 / US 10 / UK 8"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 43 / US 11.5 / UK 9.5"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 44 / US 12 / UK 10"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 45 / US 13 / UK 11"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 46 / US 13.5 / UK 11.5"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 47 / US 14 / UK 12"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 48 / US 14.5 / UK 12.5"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skostørrelse"])}
    },
    "social_security_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SV Ausweis"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsausweis (dette er relevant for tyske søkere)."])}
    },
    "studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studium"])},
    "studies_status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er din status når det gjelder studium?"])}
    },
    "study_started_title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken studie har du begynt på og ikke fullført?"])}
    },
    "study_title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken studie har du fullført ved programstart?"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tusen takk for søknaden din! Vi gleder oss over at du vil bli med på A-laget."])},
    "suitable_tasks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva slags oppgaver tror du du er egnet for? Hvorfor?"])}
    },
    "tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer ID"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg samtykker til at opplysningene mine som innhentes av BCC A-team kan brukes og deles til de formål som er beskrevet i <a href=\"https://ateam.bcc.no/privacy-policy/\" target=\"_blank\">personvernerklæringen</a>."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søknad til BCC A-team"])},
    "travel_info": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle må ha et pass (ikke bare ID-kort) innen programstart. Bor du i et land utenfor Europa må passet være gyldig i 3 måneder etter programslutt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statsborgerskap og pass"])}
    },
    "tshirt_size": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T-skjorte størrelse"])}
    },
    "under_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under 18"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp"])},
    "uploadNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp ny"])},
    "vaccination_certificate_copy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaksinasjonssertifikat"])}
    },
    "vocational_training_certified_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når planlegger du å få fagbrevet ditt, eller når fikk du det?"])}
    },
    "vocational_training_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv"])}
    },
    "vocational_training_started": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken yrkesopplæring har du begynt på og ikke fullført?"])}
    },
    "vocational_training_status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er din status når det gjelder yrkesopplæring?"])}
    },
    "vocational_training_will_complete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken fullført yrkesopplæring har du ved programstart?"])}
    },
    "vocational_training": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrkesopplæring i andre land:\n\nUSA/Canada: occupational training, vocational training, or other on-the-job training which leads to a professional certification\n\nDeutschland: Berufsbildung"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrkesopplæring"])}
    },
    "vocationalTrainingAreas": {
      "accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regnskapsmedarbeider"])},
      "auditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisor"])},
      "carpenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snekker"])},
      "cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renhold"])},
      "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokk og matproduksjon"])},
      "electrician_automatics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker - automatiker"])},
      "electrician_high_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker - sterkstrøm"])},
      "electrician_low_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriker - svakstrøm"])},
      "industrial_mechanic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrimekaniker"])},
      "industrial_sewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrisøm"])},
      "kindergarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barnehage"])},
      "landscaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleggsgartner"])},
      "machine_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskinfører"])},
      "mechanic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bil- / Lastebilmekaniker"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
      "nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sykepleier"])},
      "office_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontor / Administrasjon"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre (se kommentarfelt)"])},
      "painter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maler / Tapetserer"])},
      "plumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rørlegger"])},
      "programming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmering"])},
      "road_and_construction_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vei- og anleggsarbeider"])},
      "skilled_worker_concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fagarbeider betong"])},
      "sound_and_light_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyd- og lysdesign"])},
      "ux_ui_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI Design"])},
      "ventilation_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montør ventilasjon"])},
      "waiter_catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servitør / Catering"])},
      "woodworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømrer"])}
    },
    "volunteer_experience_other_remarks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du en kommentar på din dugnadserfaring?"])}
    },
    "volunteerExperience": {
      "cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regjøring"])},
      "construction_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggearbeid"])},
      "demolition_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivningsarbeid"])},
      "electricity_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL kontroll"])},
      "events_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventservice"])},
      "film_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmproduksjon"])},
      "game_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spillutvikling"])},
      "gardening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hagearbeid"])},
      "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varetelling"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IKT"])},
      "media_graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media / Grafisk design"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikk"])},
      "office_work_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontorarbeid / Administrasjon"])},
      "sound_light_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyd / Lys / Bilde"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva slags dugnadserfaring har du?"])},
      "warehouse_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerarbeid"])}
    },
    "work_certificates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skoleattest, fagbrev, dokumentasjon for faglig kompetanse"])}
    },
    "work_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsklær"])},
    "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidserfaring"])},
    "work_experience_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar (om arbeidserfaring)"])}
    },
    "work_life_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generell erfaring"])},
    "work_reference_person": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan du nevne en eller to referansepersoner fra jobben/dugnad som våre samarbeidspartnere eventuelt kan kontakte?"])},
      "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epostadresse"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefonnummer"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referanse"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referanser"])},
      "work_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra hvilken arbeidserfaring"])}
    },
    "work_years_of_experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall år fastansettelse i fagområdet"])}
    },
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["år"])},
    "year_group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årskull"])}
    },
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["år"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "yesOrNo": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
    }
  },
  "home": {
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er viktig at du leser gjennom denne informasjonen før du ankommer, slik at du er godt forberedt."])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg sted"])},
    "selectYearGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg årskull"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til din informasjonsportal!"])}
  },
  "incident": {
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når skjedde dette?"])}
    },
    "details_before": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fra uken, dagen, og timene - var det overtid, var alle sliten eller hadde lite søvn, var de under press? Hvordan var atmosfæren rett før hendelsen?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv relevante detaljer frem til hendelsen"])}
    },
    "follow_up_measures": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilke oppfølgingstiltak har du tatt siden hendelsen?"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken plassering gjelder dette?"])}
    },
    "others_involved": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har politiet, media, eller offentlige folk vært involvert? Beskriv din interaksjon."])}
    },
    "people_involved": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle i bilen eller knyttet til hendelsen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem var involvert?"])}
    },
    "personal_impression": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er ditt personlig inntrykk?"])}
    },
    "reportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendelsesrapport"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendelser"])},
    "type": {
      "car_accident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilulykke"])},
      "feedback_about_a_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding om en sjåfør"])},
      "personal_injury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personskade"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva slags hendelse gjelder det?"])}
    },
    "victims_testimony": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan beskriver de rammede hendelsen?"])}
    }
  },
  "interest": {
    "rate": {
      "levels": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen interesse"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lite interesse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe interesse"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høy interesse"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil definitivt"])},
        "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor interessert er du i denne søkeren?"])}
    }
  },
  "languageAssessment": {
    "backToAllEvaluations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til alle evalueringer"])},
    "backToLanguageOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til språk oversikt"])},
    "editEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger evaluering"])},
    "form": {
      "commentsToTheStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar til eleven"])},
      "evaluation_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultat fra månedens evaluering"])},
      "evaluation_status": {
        "not_passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har ikke tatt prøven"])},
        "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke påbegynt"])},
        "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått"])},
        "recommend_to_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbefaler å vente et år"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsresultat"])},
        "too_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For lav måloppnåelse"])}
      },
      "language_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk kommentar"])},
      "language_status": {
        "needs_follow_up_norwegian_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenger en til norsk intervju"])},
        "norwegian_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk godkjent"])},
        "norwegian_course_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norskkurs startet"])},
        "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke påbegynt"])},
        "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "on_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On track"])},
        "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått opptakskrav"])},
        "ready_for_norwegian_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On track"])},
        "recommend_to_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For lav måloppnåelse"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk status"])},
        "too_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For lav måloppnåelse"])}
      },
      "language_subjects": {
        "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått"])},
        "passed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato bestått"])},
        "subjects": {
          "ateam_everyday_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-lagshverdag"])},
          "church_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menighetsliv"])},
          "city_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byliv"])},
          "level-a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivå A1"])},
          "level-a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivå A2"])},
          "level-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivå B1"])},
          "youth_meeting_and_bu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungdomsmøte og BU"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg kan ..."])}
      },
      "more_details": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(F.eks.: lærevansker, personlighet som påvirker læringsprosessen, andre oppdagelser)"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er det noe mer vi burde vite om eleven?"])}
      },
      "note_to_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notat til elev"])},
      "on_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er eleven i rute med å bestå norsk-intervjuet før A-Team starter?"])},
      "participation_in_classes": {
        "active_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv deltakelse"])},
        "low_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lite deltakelse"])},
        "medium_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middels deltakelse"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakelse under undervisningen i siste måned"])}
      },
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremgang"])},
      "seePreviousAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se tidligere evalueringer"])},
      "studentParticipation": {
        "note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eleven har deltatt i undervisningen ", _interpolate(_named("in")), " av ", _interpolate(_named("out_of")), " timer de siste to månedene."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppmøte"])}
      },
      "submission_of_homework": {
        "not_submitted_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har ikke levert alle"])},
        "submitted_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levert alle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlevering av lekser i det siste måned"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag ny evaluering"])}
    },
    "giveEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi evaluering"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notat"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mer"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultat"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk evaluering"])}
  },
  "languageClass": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer oppmøte"])},
    "goToButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språkkurs"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrere oppmøte"])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn data ..."])},
  "menu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjem"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse"])},
    "languageClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språkkurs"])},
    "languageClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språkkurs"])},
    "languageStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk status"])},
    "myFinances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min økonomi"])},
    "norwegianAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk evalueringer"])},
    "overnighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnatting"])},
    "participantProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakerens profil"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere"])},
    "placements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasseringer"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referanse"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søknad"])},
    "reportIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporter hendelse"])},
    "wnt-placements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WnT Plasseringer"])}
  },
  "months": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desember"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])}
  },
  "nextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste måned"])},
  "overnighting": {
    "events": {
      "brothers_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Brødrestevne"])},
      "easter_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Vårstevne 1 (Påske)"])},
      "feast_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Festhelg"])},
      "sisters_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Søstrestevne"])},
      "spring_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Vårstevne 2 (Pinse)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventer"])}
    },
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er selv ansvarlig for å bestille overnatting under BCC-stevner. Hvis familien din ikke har en avtale, eller hvis vennene dine ikke har nok plass, kan A-team hjelpe med å bestille overnatting for deg (mot gebyr).\n\nDu kan gjøre endringer i statusen din helt frem til fristen går ut. Fristen skal kommuniseres på et nærmere tidspunkt."])},
    "noApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke søkt på A-team enda."])},
    "status": {
      "arranged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har ordnet overnatting"])},
      "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg sjekker opp"])},
      "need_overnighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ønsker å bestille overnatting"])},
      "no_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen svar"])},
      "not_relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg deltar ikke"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    }
  },
  "participants": {
    "hasSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har aktiv abonnement"])},
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke har en aktiv abonnement"])},
    "participantsNotAjour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere som ikke er ajour"])},
    "participantsNotPaidThisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere som har ikke betalt denne måned"])},
    "statusInPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosentstatus"])}
  },
  "previousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige måned"])},
  "reference": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasjonen dere legger inn i dette skjemaet er først og fremst tenkt til å gi et inntrykk av hvordan søkeren er som person. Vårt mål er at søkeren skal ha en optimal opplevelse på A-team. Derfor er all informasjon som dere gir her meget avgjørende at det skal lykkes fullt ut.\n\nAnsvar for godkjenning av den enkelte deltaker ligger på den enkelte menighet og ikke sentralt i A-team organisasjon.\n\nVi understreker viktigheten av at dere snakker med dugnads- og aktivitetsledere / mentorer som kjenner ungdommene godt for å gi oss et best mulig bilde av vedkommende.\n\nVi bruker informasjonen sentralt i A-team administrasjon. Dersom det er nødvendig, viser vi dette til andre ansvarspersoner i A-Team programmet. Dersom dere imidlertid har sensitiv informasjon eller åpenbare forhold som dere mener vi bør vite om, ber vi dere krysse av for at dere ønsker dialog (nederst i skjemaet) i stedet for å beskrive dette nærmere. Vi vil da ta kontakt med dere på telefon/epost for videre avklaringer. All informasjon som blir sendt på denne måten blir behandlet strengt konfidensielt.\n\nVennligst legg inn deres kommentarer / tilbakemelding i feltene under."])},
    "form": {
      "applicant_abilities": {
        "adapt_to_new_social_situations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evne til å tilpasse seg til nye sosiale situasjoner"])},
        "collaboration_with_peers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samarbeid med jevnaldrende"])},
        "collaboration_with_responsibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samarbeid med ansvarlige"])},
        "on_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Å komme tidsnok"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På en skale fra 1-5 vurder søkerens evner på følgende områder (hvor 1 er svak og 5 er sterk):"])}
      },
      "approval": {
        "confirmation_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjenning er ikke endelig. Hvis noe endre helt frem til oppstartsdagen, må du kontakte A-team sentralt."])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvar for godkjenning av den enkelte deltaker ligger på den enkelte menighet. Dersom dere er i tvil om søkeren er egnet for A-Team, vennligst ta kontakt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg godkjenner at søkeren blir tatt opp i ungdomsprogrammet A-team."])}
      },
      "choose_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg ett av følgende:"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har dere kommentarer, generelle tilbakemeldinger eller spørsmål?"])},
      "contact": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn og nummer for kontaktperson som kjenner til forholdet:"])}
      },
      "contributors": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til bidragsyter"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn navn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referansen er utfylt i samarbeid med følgende personer:"])}
      },
      "conversation_with_the_applicant": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er påkrevd at det utføres en personlig samtale med søkeren. Dette anser vi som en viktig ledd i å kvalitetssikre søknadsprosessen og at det blir vellykket opphold."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basert på din samtale med søkeren, er det din oppfatning om at søkeren forstår hva A-Team går ut på og hva forventes ifm. programmet?"])}
      },
      "dialog_required": {
        "needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, dialog er ønsket"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dersom det er forhold som dere mener vi bør være spesielt oppmerksomme på eller som kan medføre utfordringer rundt at personen skal kunne få et godt opphold på ungdomsprogrammet, ber vi om at dere krysser av «Ja» her. Vi vil da ta kontakt med en av dere for nærmere avklaringer. Dialogen foregår deretter med en ansvarsperson for A-Team programmet."])},
        "notNecessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei, dialog er ikke nødvendig"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønsket dialog"])}
      },
      "driver": {
        "advise_against": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi fraråder å bruke ham/henne som sjåfør"])},
        "dont_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kjenner ikke søkerens kjøreatferd godt nok til å kunne gjøre en vurdering"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er viktig for oss å velge sjåfører med omhu, for å optimalisere sikkerheten til A-teamet så godt som mulig. Hvis du er i tvil eller mistenker at det kan være en grunn for å fraråde, er det viktig å undersøke det med dem som har erfaring med søkeren. "])},
        "recommandation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi anbefaler vedkommende som sjåfør"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjenner du til en bestemt grunn, at du ikke vil anbefale å bruke søkeren til å kjøre bil / ha ansvar for å kjøre flere ungdommer?"])}
      },
      "health_problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjenner dere til noen helse utfordringer som tilsier at søkeren ikke egner seg til A-team?\nVennligst opplys om kjente helse utfordringer - det er helt avgjørende for at oppholdet skal være vellykket."])},
      "is_approved_local_worker": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er søkeren per i dag en godkjent barne- og ungdomsarbeider i lokalmenigheten?"])}
      },
      "leadership": {
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søkeren har ikke typiske lederegenskaper"])},
        "often": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søkeren har ofte tatt lederskap i slike gruppestørrelser"])},
        "potential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi tror at søkeren har potensial til det"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har søkeren evne til å lede et team opp til 3 personer?"])}
      },
      "norwegian_as_mothers_tongue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsk som morsmål"])},
      "norwegian_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har vært i kontakt med norsklæreren lokalt, og kan bekrefte at søkeren enten er «on track» eller vil være det innen desember i henhold til norskundervisningsopplegget."])},
      "personality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplever du søkeren som innadvendt eller utadvendt?"])},
      "personality2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har søkeren en tendens til å ta initiativ i sosiale settinger eller vente på at andre skal ta kontakt?"])},
      "responsibility": {
        "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi har sett at søkeren ser oppgaver og tar ansvar for at disse gjennomføres"])},
        "needs_guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi har sett at søkeren trenger veiledning og hjelp i større grad ved gjennomføring av oppgaver"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ser oppgaver som må gjøres og viser god ansvarsfølelse for å få ting gjennomført)"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi en vurdering på hvordan søkeren viser ansvar og gjennomfører tildelte oppgaver. Om de ser oppgaver som må gjøres og viser <span style=\"text-decoration: underline\">god ansvarsfølelse</span> for å få ting gjennomført."])},
        "with_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi har sett at søkeren kan ta oppgaver og gjennomfører disse med noe veiledning og hjelp"])}
      },
      "seeOthersFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se de andres tilbakemelding"])},
      "skills": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til ferdighet"])},
        "balanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["balansert"])},
        "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kreativ"])},
        "enduring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utholdende"])},
        "groups_heartbeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hvilepulsen i gruppa"])},
        "hard_working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hardarbeidende"])},
        "motivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motiverende"])},
        "musical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["musikalsk"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan velge flere alternativ."])},
        "otherPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
        "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansvarsfull"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har allerede sett disse ferdighetene hos vedkommende (hvis ja, kryss av)"])},
        "well_being_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trivselsskaper"])}
      },
      "special_circumstances": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deres ærlige svar er helt avgjørende for at oppholdet skal være vellykket."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjenner dere til en av følgende:<ul><li>Spesielle hendelser/forhold/trauma i søkerens liv eller familie som påvirker ungdommen? (F. eks. skilsmisse, dødsfall)</li><li>Helse utfordringer som tilsier at søkeren ikke egner seg til A-team?</li><li>Avvikende adferd</li></ul>"])}
      },
      "tasks_in_local_church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilke oppgaver eller ansvar har søkeren i lokalmenigheten?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørsmål om ferdigheter og arbeidsmetoder"])},
      "work_life": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ifm. at vi sender A-team til mange forskjellige steder i verden, trenger vi mer informasjon: Kan de takle lange arbeidsdager over en lengere periode, mange nye inntrykk, samt opphold med forskjellige typer mennesker og kulturer? Hvor tilpasningsdyktig og fleksibel er han/hun på kortsiktige endringer? Fullfører søkeren oppgaver uten å bli minnet på det?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er det noe andre områder hvor du mener søkeren kan ha behov for ekstra støtte under programmet?"])}
      },
      "workparty_contribution_and_cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv noen linjer om hvordan dere har opplevd samarbeidet og hvordan søkeren har utført oppgavene."])}
    },
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Referanseskjema for inntak sommer ", _interpolate(_named("year"))])}
  },
  "registration": {
    "alreadyApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede søkt til"])},
    "applicationsStart": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Søknadsportalen åpner ", _interpolate(_named("start"))])},
    "applyAgain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil søke igjen da kan du sende en e-post til ", _interpolate(_named("email")), "."])},
    "ateam": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team er først og fremst en uvurderlig investering i din egen framtid – for deg som ønsker å leve et helhjertet disippelliv!\n\nEt år på A-team er et rikt år på giversiden, et år i tjeneste, arbeid, og personlig utvikling. Du vil få rike erfaringer og opplevelser, blir bedre i det du kan fra før og lærer en masse nytt. Du lærer folk å kjenne fra mange steder fra hele verden, og kan få vennskap som varer og fordypes livet ut. Gjennom bibelstudier og sammenkomster styrkes troen på ditt himmelske kall og du vil få en dypere forståelse av hva disippellivet betyr nettopp for deg. Du vil få rikelig anledning til å praktisere et helhjertet kristenliv tett på dine venner samtidig som du inkluderes og får bidra i den lokalmenigheten du tilhører. Etter et år på A-team vil du kunne reise hjem som en skikket mentor, med et brennende ønske om å tjene og gi ditt liv i de helliges tjeneste.\n\nNår man flytter til en ny by eller et nytt land, er det mange detaljer som må på plass. Vi har samarbeidspartnere som kan tilby deg arbeid, transport og bolig. Informasjon som skal videreformidles til samarbeidspartnere våre finner du i siste delen av søknaden.\n\nVi ser frem til å motta din søknad og vil gjøre alt vi kan for at dette skal bli til et av de beste årene i ditt liv!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er A-team?"])}
    },
    "deadlinePassed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Søknadsfristen gikk ut den ", _interpolate(_named("deadline")), ".\nSøknader vil nå bli lagt til på ventelisten.\nLes her for mer info: <a href=\"", _interpolate(_named("faqUrl")), "\" class=\"underline\">FAQ - Hvordan fungerer venteliste?</a>"])},
    "deadlineToApply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fristen for å søke: ", _interpolate(_named("deadline"))])},
    "initialCheck": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyll inn grunnleggende informasjon for å se hvilke av BCCs ungdomsprogrammer og partnerprogrammer du er kvalifisert for:"])},
      "eosMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basert på valgene dine ovenfor kvalifiserer du til program gjennom BCC A-team, WorknTravel, og Samvirk.\n\nTrykk her for å bekrefte at du ønsker å søke på disse tre programmer samtidig."])},
      "firstPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Søknaden gjelder for:"])},
      "nonEosMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basert på valgene dine ovenfor kvalifiserer du til YEP- og A-team-programmene. Du kan søke på begge disse samtidig gjennom BCC Event.\n\nVennligst fortsett med søknaden din her: <a href=\"https://event.bcc.no/yep/\" class=\"underline\">https://event.bcc.no/yep/</a>"])},
      "secondPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er det noen andre land du har (eller vil skaffe) pass fra før programmet starter?"])},
      "secondPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Hvilket land er du statsborger i?"])},
      "stepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinn 1"])},
      "stepThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinn 3 av 3"])},
      "stepThreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne delen av søknaden vil bli videresendt til våre samarbeidspartnere, som vil ta kontakt med et tilbud om arbeid, transport og bolig. Vær oppmerksom på at utvalgte deler av informasjonen du oppgir her (din arbeidserfaring osv.), kan bli brukt for å bygge en digital CV, som disse partnere deler med andre."])},
      "stepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinn 2 av 3"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Hvis informasjonen ovenfor ikke er korrekt, må du logge inn med riktig bruker."])}
    }
  },
  "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A-team Program ", _interpolate(_named("currentYear")), " - ", _interpolate(_named("nextYear"))])},
  "weekdays": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mandag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tirsdag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onsdag"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torsdag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fredag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lørdag"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["søndag"])}
  },
  "weekdaysShort": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["man"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tir"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ons"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tor"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fre"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lør"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["søn"])}
  }
}
import { createRouter, createWebHistory } from "vue-router";
import Finance from "@/views/Finance.vue";
import SuccessfulPayment from "@/views/SuccessfulPayment.vue";
import SuccessfulSubscription from "@/views/SuccessfulSubscription.vue";
import CancelledPayment from "@/views/CancelledPayment.vue";
import CancelledSubscription from "@/views/CancelledSubscription.vue";
import Registration from "@/views/Registration.vue";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import LanguageAssessmentsDashboard from "@/views/LanguageAssessmentsDashboard.vue";
import EmploymentsDashboard from "@/views/EmploymentsDashboard.vue";
import WntEmploymentsDashboard from "@/views/WntEmploymentsDashboard.vue";
import ParticipantsDashboard from "@/views/ParticipantsDashboard.vue";
import LanguageClassesDashboard from "@/views/LanguageClassesDashboard.vue";
import Participant from "@/views/Participant.vue";
import Incident from "@/views/Incident.vue";
import Overnighting from "@/views/Overnighting.vue";
import Aksjon from "@/views/Aksjon.vue";
import Reference from "@/views/Reference.vue";
import Application from "@/views/Application.vue";
import Employment from "@/views/Employment.vue";
import LanguageStatus from "@/views/LanguageStatus.vue";
import LanguageAssessment from "@/views/LanguageAssessment.vue";
import LanguageClass from "@/views/LanguageClass.vue";
import Interest from "@/views/Interest.vue";
import WntProfile from "@/views/WntProfile.vue";
import InfoPage from "@/views/InfoPage.vue";
import EventPage from "@/views/EventPage.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
    },
    {
      path: "/dashboard/language",
      name: "norwegianAssessments",
      component: LanguageAssessmentsDashboard,
    },
    {
      path: "/dashboard/employments",
      name: "employments",
      component: EmploymentsDashboard,
    },
    {
      path: "/dashboard/wnt-employments",
      name: "wnt-employments",
      component: WntEmploymentsDashboard,
    },
    {
      path: "/dashboard/participants",
      name: "participants",
      component: ParticipantsDashboard,
    },
    {
      path: "/dashboard/participants/:id",
      name: "participantProfile",
      component: Participant,
    },
    {
      path: "/employment/:id",
      name: "employment",
      component: Employment,
    },
    {
      path: "/incident/:id",
      name: "incident",
      component: Incident,
    },
    {
      path: "/dashboard/language-classes",
      name: "languageClasses",
      component: LanguageClassesDashboard,
    },
    {
      path: "/finance",
      name: "myFinances",
      component: Finance,
    },
    {
      path: "/finance/successful-payment",
      name: "successfulPayment",
      component: SuccessfulPayment,
    },
    {
      path: "/finance/successful-subscription",
      name: "successfulSubscription",
      component: SuccessfulSubscription,
    },
    {
      path: "/finance/cancelled-payment",
      name: "cancelledPayment",
      component: CancelledPayment,
    },
    {
      path: "/finance/cancelled-subscription",
      name: "cancelledSubscription",
      component: CancelledSubscription,
    },
    {
      path: "/overnighting",
      name: "overnighting",
      component: Overnighting,
    },
    {
      path: "/aksjon",
      name: "aksjon",
      component: Aksjon,
    },
    {
      path: "/registration",
      name: "registration",
      component: Registration,
    },
    {
      path: "/application/:id",
      name: "application",
      component: Application,
    },
    {
      path: "/application/:id/reference",
      name: "reference",
      component: Reference,
    },
    {
      path: "/application/:id/language-status",
      name: "languageStatus",
      component: LanguageStatus,
    },
    {
      path: "/application/:id/language-assessment/:month",
      name: "languageAssessment",
      component: LanguageAssessment,
    },
    {
      path: "/application/:id/employer/:employer_id",
      name: "interest",
      component: Interest,
    },
    {
      path: "/application/:id/wnt/:employer_id",
      name: "wnt-profile",
      component: WntProfile,
    },
    {
      path: "/language-classes/:id",
      name: "languageClass",
      component: LanguageClass,
    },
    {
      path: "/info/:slug",
      name: "info-page",
      component: InfoPage,
    },
    {
      path: "/event/:slug",
      name: "event-page",
      component: EventPage,
    },
  ],
});

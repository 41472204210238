<template>
  <div v-if="user" class="grid gap-8">
    <router-link to="/" class="disable-link flex gap-2">
      <span class="text-xl leading-6 font-semibold">{{ $t('incident.reportTitle') }}</span>
    </router-link>

    <div class="container">
      <form @submit.prevent="reportIncident" class="xl:max-w-[90%] w-full">
        <FormSection class="!py-0">
          <div class="inline-flex flex-col space-y-2">
            <label class="bcc-form-label">{{ t('incident.type.title') }}</label>
            <v-select placeholder="---" v-model="store.incident.type" :reduce="option => option.value"
              :options="store.list.type" />
          </div>

          <BccInput :label="t('incident.date.title')" v-model="store.participationDate" autocomplete="off"
            :placeholder="'Ex: ' + getCurrentDate()" id="participationDate">
            <BccButton variant="secondary" type="button" @click="chooseCurrentDate()">{{ t('form.chooseCurrentDate') }}
            </BccButton>
          </BccInput>

          <div class="inline-flex flex-col space-y-2">
            <label class="bcc-form-label">{{ t('incident.location.title') }}</label>
            <v-select placeholder="---" v-model="store.incident.location" :reduce="option => option.value"
              :options="store.list.locations" />
          </div>

          <BccInput :label="t('incident.details_before.title')" v-model="store.incident.details_before" type="text" is="textarea" rows="4">
            {{ t('incident.details_before.subtitle') }}
          </BccInput>

          <BccInput :label="t('incident.victims_testimony.title')" v-model="store.incident.victims_testimony" type="text" is="textarea" rows="4" />
          <BccInput :label="t('incident.personal_impression.title')" v-model="store.incident.personal_impression" type="text" is="textarea" rows="4" />
          <BccInput :label="t('incident.follow_up_measures.title')" v-model="store.incident.follow_up_measures" type="text" is="textarea" rows="4" />
        </FormSection>

        <BccButton variant="primary" class="mr-auto" :disabled="store.formLoading"
          :class="{ 'cursor-not-allowed': store.formLoading }" type="submit">
          <div class="spinner" :class="{ loading: store.formLoading }"></div>
          {{ t('form.submit') }}
        </BccButton>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { readItems, readUsers, createItem, updateItem } from '@directus/sdk';
import { useRoute, useRouter } from 'vue-router'
import FormSection from '../components/FormSection'
import { BccButton, BccInput } from '@bcc-code/design-library-vue'

import vSelect from "vue-select"
import Pikaday from "pikaday"
import 'pikaday/css/pikaday.css'
import 'pikaday/css/triangle.css'

import { getCurrentDate } from '../helpers/fields.js'

const route = useRoute()
const router = useRouter()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

let selectOptionsKeysTranslatable = {
  type: ['feedback_about_a_driver', 'car_accident', 'personal_injury']
}
let selectOptionsList = {}

// Translatable values
Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
  selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('incident.' + key + '.' + val) }))
})

const store = reactive({
  selectOptionsKeysTranslatable: selectOptionsKeysTranslatable,
  list: selectOptionsList,

  incident: {
    id: null,
    type: null
  },
  participationDate: null,
  dateInputs: {},
  locations: [],
  participants: [],
  formLoading: false
})

watch(
  locale,
  () => updateSelectOptionsLists()
)

onBeforeMount(async () => {
  await getIncident()
  await getLocations()
  await getParticipants()
})

onMounted(async () => {
  defineDataInput('participationDate', { maxDate: new Date() })
})

function defineDataInput(id, extraFields = {}) {
  store.dateInputs[id] = new Pikaday({
    field: document.getElementById(id),
    theme: 'triangle-theme',
    firstDay: 1,
    i18n: {
      "previousMonth": t('previousMonth'),
      "nextMonth": t('nextMonth'),
      "months": [t('months.1'), t('months.2'), t('months.3'), t('months.4'), t('months.5'), t('months.6'), t('months.7'), t('months.8'), t('months.9'), t('months.10'), t('months.11'), t('months.12')],
      "weekdays": [t('weekdays.7'), t('weekdays.1'), t('weekdays.2'), t('weekdays.3'), t('weekdays.4'), t('weekdays.5'), t('weekdays.6')],
      "weekdaysShort": [t('weekdaysShort.7'), t('weekdaysShort.1'), t('weekdaysShort.2'), t('weekdaysShort.3'), t('weekdaysShort.4'), t('weekdaysShort.5'), t('weekdaysShort.6')]
    },
    onSelect: function () {
      store[id] = this.toString('YYYY-MM-DD')
    },
    ...extraFields
  })
}

async function getIncident() {
  if (route.params.id == '+') return

  const incident = await directus.request(
    readItems('incidents', {
      filter: {
        id: { _eq: route.params.id }
      },
      fields: ['*']
    })
  )
  if (!incident.length) return

  store.incident = incident[0]
  store.participationDate = store.incident.date.split('T')[0] // support only date for now
}

async function getLocations() {
  const locations = await directus.request(
    readItems('locations', {
      filter: { status: 'active' },
      sort: "name ",
      limit: -1
    })
  )
  if (!locations.length) return

  store.locations = locations
  store.list.locations = locations.map(location => ({ 'value': location.id, 'label': location.name }))
}

async function getParticipants() {
  let filter = {}

  if (directusStore.isAdmin()) {
    filter = {
      "role": {
        "_eq": "7888ed54-8899-49e7-ac34-26d231ace111"
      },
      "employments": {
        "_nnull": true
      }
    }
  } else if (directusStore.userHasRole('stedsansvarlig') || directusStore.userHasRole('applicant')) {
    filter = {
      "role": {
        "_eq": "7888ed54-8899-49e7-ac34-26d231ace111"
      },
      "employments": {
        "location": {
          "_in": user.employments.map(employment => employment.location.id)
        }
      }
    }
  }

  const participants = await directus.request(
    readUsers({
      filter: filter,
      fields: ["id", "first_name", "last_name", "employments.location.name"],
      limit: -1
    })
  )
  if (!participants.length) return

  store.participants = participants
  store.list.participants = participants.map(participant => ({
    'value': participant.id,
    'label': `${participant.first_name} ${participant.last_name} ${ participant.employments.slice(-1)[0].location
      ? '(' + participant.employments.slice(-1)[0].location.name + ')'
      : ''
    }`
  }))
}

async function reportIncident() {
  let data = {
    type: store.incident.type,
    date: store.participationDate,
    location: store.incident.location,
    details_before: store.incident.details_before,
    victims_testimony: store.incident.victims_testimony,
    personal_impression: store.incident.personal_impression,
    follow_up_measures: store.incident.follow_up_measures,
  }

  !store.incident.id
    ? await directus.request(createItem('incidents', data))
    : await directus.request(updateItem('incidents', store.incident.id, data))

  router.push({ path: '/dashboard/participants' })
}

function chooseCurrentDate() {
  store.participationDate = getCurrentDate()
}

function updateSelectOptionsLists() {
  Object.entries(store.selectOptionsKeysTranslatable).map(([key, value]) => {
    store.list[key] = value.map(val => ({ 'value': val, 'label': t('incident.' + key + '.' + val) }))
  })
}
</script>
<template>
  <div class="grid gap-8">
    <router-link :to="'/application/'+route.params.id+'/language-status'" class="flex gap-2 print:hidden">
      <KeyboardArrowLeftIcon class="h-6" />
      <span class="text-lg leading-6 font-semibold">{{ $t('languageAssessment.backToLanguageOverview') }}</span>
    </router-link>

    <h1 class="text-xl leading-6 font-semibold">{{ $t('dashboard.actions.evaluation') }}</h1>

    <div v-if="store.application" class="container">
      <section v-if="!directusStore.userHasRole('applicant')" class="grid gap-8">
        <form @submit.prevent="studentEvaluation" class="grid gap-8">
          <BccInput v-model="store.studentEvaluation.evaluation_result" class="col-span-full print:hidden" :label="t('languageAssessment.form.evaluation_result')"
              :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="8" />

          <div>
            <label class="font-bold text-sm mb-2 inline-block">{{ t('languageAssessment.form.evaluation_status.title') }}</label>
            <v-select placeholder="---" v-model="store.studentEvaluation.status" :reduce="option => option.value"
              :options="store.list.evaluation_status"
              :selectable="option => option.value" />
          </div>

          <div class="grid gap-2">
            <BccInput v-model="store.studentEvaluation.note_to_student" class="col-span-full print:hidden" :label="t('languageAssessment.form.note_to_student')"
              :placeholder="t('form.inputPlaceholder')" type="text" is="textarea" rows="4" />
          </div>

          <div>
            <label class="font-bold text-sm mb-2 inline-block">{{ t('languageAssessment.form.participation_in_classes.title') }}</label>
            <v-select placeholder="---" v-model="store.studentEvaluation.participation_in_classes" :reduce="option => option.value"
              :options="store.list.participation_in_classes"
              :selectable="option => option.value" />
          </div>

          <div>
            <label class="font-bold text-sm mb-2 inline-block">{{ t('languageAssessment.form.submission_of_homework.title') }}</label>
            <v-select placeholder="---" v-model="store.studentEvaluation.submission_of_homework" :reduce="option => option.value"
              :options="store.list.submission_of_homework"
              :selectable="option => option.value" />
          </div>

          <BccButton variant="primary" class="mr-auto print:hidden" :disabled="store.formLoading"
            :class="{ 'cursor-not-allowed': store.formLoading }" type="submit">
            <div class="spinner" :class="{ loading: store.formLoading }"></div>
            {{ t('form.save') }}
          </BccButton>
        </form>
      </section>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, reactive, watch } from 'vue'
import { useDirectusStore } from '../stores/directus'
import { readItems, createItem, updateItem } from '@directus/sdk';
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'
import { BccButton, BccInput } from '@bcc-code/design-library-vue'

import { useI18n } from 'vue-i18n'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const route = useRoute()
const router = useRouter()
const directusStore = useDirectusStore()
const directus = directusStore.directus

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

let selectOptionsKeysTranslatable = {
  evaluation_status: ['not_started', 'passed', 'too_low', 'recommend_to_wait', 'not_passed'],
  participation_in_classes: ['active_participation', 'medium_participation', 'low_participation'],
  submission_of_homework: ['submitted_all', 'not_submitted_all']
}

let selectOptionsList = {}

// Translatable values
Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
  selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('languageAssessment.form.' + key + '.' + val) }))
})

const store = reactive({
  access_token: null,
  selectOptionsKeysTranslatable: selectOptionsKeysTranslatable,
  list: selectOptionsList,

  application: null,
  languageAssessment: null,
  languageEvaluations: [],
  studentEvaluation: {
    id: null,
    evaluation_result: '',
    status: 'not_started',
    note_to_student: '',
    participation_in_classes: null,
    submission_of_homework: null
  },

  updateFailed: false,
  formLoading: false
})

onBeforeMount(async () => {
  store.access_token = await directus.getToken()

  await getApplication()
  await getLanguageAssessment()
})

watch(
  locale,
  () => updateSelectOptionsLists()
)

async function getApplication() {
  const application = await directus.request(
    readItems('applications', {
      filter: {
        id: { _eq: route.params.id },
      },
      fields: ['*', 'language_assessments.*.*', 'local_church.*', 'profile_picture.*.*'],
    })
  )
  if (!application.length) return

  store.application = application[0]

  store.languageAssessment = store.application.language_assessments.length ? store.application.language_assessments[0] : null
}

async function getLanguageAssessment() {
  if (!store.languageAssessment?.language_evaluations)
    return

  store.languageEvaluations = store.languageAssessment.language_evaluations

  const thisMonthEvaluation = store.languageEvaluations.find(e => e.month_id == parseInt(route.params.month))

  if (!thisMonthEvaluation) return

  const evaluations = await directus.request(
    readItems('student_periodic_evaluations', {
      filter: {
        id: { _eq: thisMonthEvaluation.evaluation_id.key },
      },
      fields: ['*'],
    })
  )
  if (!evaluations.length) return

  store.studentEvaluation = evaluations[0]
}

async function studentEvaluation() {
  store.formLoading = true

  if (!store.studentEvaluation.id) {
    // Create student periodic evaluation for this month
    delete store.studentEvaluation.id

    const studentPeriodicEvaluation = await directus.request(createItem('student_periodic_evaluations', store.studentEvaluation))
    const newLanguageEvaluation = {
      "evaluation_id": {
        "key": studentPeriodicEvaluation.id,
        "collection": "student_periodic_evaluations"
      },
      "month_id": parseInt(route.params.month)
    }

    store.languageEvaluations.push(newLanguageEvaluation)

    if (!store.languageAssessment) {
      // There is no language assessment created for this student
      const languageAssessment = await directus.request(createItem('language_assessments', {
        "application_id": store.application.id,
        "language_evaluations": store.languageEvaluations
      }))

      // This application has no language assessment linked
      await directus.request(updateItem('applications', store.application.id, {
        "language_assessments": [{
          id: languageAssessment.id
        }]
      }))
    }
    else {
      // There is a language assessment
      await directus.request(updateItem('language_assessments', store.languageAssessment.id, {
        "language_evaluations": store.languageEvaluations
      }))
    }
  } else {
    // Update student periodic evaluation
    const studentEvaluationId = store.studentEvaluation.id
    delete store.studentEvaluation.id
    
    await directus.request(updateItem('student_periodic_evaluations', studentEvaluationId, store.studentEvaluation))
  }

  // await directus.request(updateItem('applications', store.application.id, {
  //   language_status: store.studentEvaluation.status
  // }))

  store.formLoading = false

  router.push({ path: '/application/' + store.application.id + '/language-status/' })
}

function updateSelectOptionsLists() {
  Object.entries(store.selectOptionsKeysTranslatable).map(([key, value]) => {
    store.list[key] = value.map(val => ({ 'value': val, 'label': t('languageAssessment.form.' + key + '.' + val) }))
  })
}
</script>
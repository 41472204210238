<template>
  <div v-if="directusStore.user" class="grid gap-8">
    <div class="container min-w-0">
      <header class="flex gap-6 mb-4 flex-col md:flex-row">
        <router-link to="/" class="disable-link flex gap-2">
          <span class="text-xl leading-6 font-semibold">{{ $t('menu.overnighting') }}</span>
        </router-link>

        <BccInput class="md:ml-auto" v-model="store.search_name" :placeholder="t('dashboard.filter.search')" v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')" />
      </header>

      <template v-if="directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')">

        <BccTable
          :columns="columns()"
          :items="filteredApplications"
          v-model:sortBy="store.sortBy"
          v-model:sortDirection="store.sortDirection"
          class="max-w-full overflow-x-auto"
          v-if="!store.isLoading"
        >

          <template #item.display.feast_weekend="{ item }">

            <BccBadge v-if="item.display.feast_weekend == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.feast_weekend == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.feast_weekend == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.feast_weekend == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.display.brothers_conference="{ item }">
              
            <BccBadge v-if="item.display.brothers_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.brothers_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.brothers_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.brothers_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.display.sisters_conference="{ item }">
            
            <BccBadge v-if="item.display.sisters_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.sisters_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.sisters_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.sisters_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.display.easter_conference="{ item }">
            
            <BccBadge v-if="item.display.easter_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.easter_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.easter_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.easter_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

          <template #item.display.spring_conference="{ item }">
            
            <BccBadge v-if="item.display.spring_conference == 'not_relevant'" context="neutral">{{
              t("overnighting.status.not_relevant")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.spring_conference == 'checking'" context="info">{{
              t("overnighting.status.checking")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.spring_conference == 'need_overnighting'" context="danger">{{
              t("overnighting.status.need_overnighting")
            }}</BccBadge>

            <BccBadge v-else-if="item.display.spring_conference == 'arranged'" context="success">{{
              t("overnighting.status.arranged")
            }}</BccBadge>

            <BccBadge v-else context="warning">{{
              t("overnighting.status.no_answer")
            }}</BccBadge>

          </template>

        </BccTable>

        <div v-if="store.isLoading" class="w-full text-center my-4">
          <BccButton>{{ t('loading') }}</BccButton>
        </div>

      </template>

      <template v-else>
        <p class="text-body-base text-secondary whitespace-pre-wrap">{{ t('overnighting.info') }}</p>

        <BccBadge v-if="store.showNoApplicationsMessage" class="mt-6" context="warning">{{
          t("overnighting.noApplication")
        }}</BccBadge>

        <form @submit.prevent="saveOvernighting" class="xl:max-w-[90%] w-full">
          <FormSection>
            <table class="">
              <thead>
                <tr>
                  <th class="font-semibold text-left w-[200px]">{{ t('overnighting.events.title') }}</th>
                  <th class="font-semibold text-left">{{ t('overnighting.status.title') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(status, event) in store.overnighting.events" :key="event">
                  <tr class="event">
                    <td>{{ t('overnighting.events.' + event) }}</td>
                    <v-select class="my-2" placeholder="---" required v-model="store.overnighting.events[event]"
                      :reduce="option => option.value" :options="store.list.status" />
                  </tr>
                </template>
              </tbody>
            </table>
          </FormSection>

          <BccButton variant="primary" class="mr-auto" :disabled="store.isLoading"
            :class="{ 'cursor-not-allowed': store.isLoading }" type="submit">
            <div class="spinner" :class="{ loading: store.isLoading }"></div>
            {{ t('form.save') }}
          </BccButton>
        </form>
      </template>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, computed, watch } from 'vue'
import { useI18n } from "vue-i18n";
import { useDirectusStore } from "../stores/directus.js";
import { readItems, createItem, updateItem } from '@directus/sdk';
import {
  BccInput,
  BccTable,
  BccBadge,
  BccButton,
} from "@bcc-code/design-library-vue";
import FormSection from '../components/FormSection'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: "local",
});

let selectOptionsKeysTranslatable = {
  status: ['not_relevant', 'checking', 'need_overnighting', 'arranged']
}
let selectOptionsList = {}

// Translatable values
Object.entries(selectOptionsKeysTranslatable).map(([key, value]) => {
  selectOptionsList[key] = value.map(val => ({ 'value': val, 'label': t('overnighting.' + key + '.' + val) }))
})

const store = reactive({
  isLoading: true,
  applications: [],
  showNoApplicationsMessage: false,
  search_name: '',
  sortBy: "display.name",
  sortDirection: "ascending",

  selectOptionsKeysTranslatable: selectOptionsKeysTranslatable,
  list: selectOptionsList,

  overnighting: {
    id: null,
    application_id: null,
    events: {
      feast_weekend: null,
      brothers_conference: null,
      sisters_conference: null,
      easter_conference: null,
      spring_conference: null,
    }
  },
})

watch(
  locale,
  () => updateSelectOptionsLists()
)

const columns = () => [
  {
    text: t("dashboard.name"),
    key: "display.name"
  },
  {
    text: t("overnighting.events.feast_weekend"),
    key: "display.feast_weekend",
    sortMethod: (a, b) => {
      let modifier = 1;
      if (store.sortDirection === "descending") modifier = -1;

      if (a.display.feast_weekend == null) {
          return 1 * modifier;
      }
      if (b.display.feast_weekend == null) {
          return -1 * modifier;
      }

      if (a.display.feast_weekend < b.display.feast_weekend) return -1 * modifier;
      if (a.display.feast_weekend > b.display.feast_weekend) return 1 * modifier;
      return 0;
    }
  },
  {
    text: t("overnighting.events.brothers_conference"),
    key: "display.brothers_conference",
    sortMethod: (a, b) => {
      let modifier = 1;
      if (store.sortDirection === "descending") modifier = -1;

      if (a.display.brothers_conference == null) {
          return 1 * modifier;
      }
      if (b.display.brothers_conference == null) {
          return -1 * modifier;
      }

      if (a.display.brothers_conference < b.display.brothers_conference) return -1 * modifier;
      if (a.display.brothers_conference > b.display.brothers_conference) return 1 * modifier;
      return 0;
    }
  },
  {
    text: t("overnighting.events.sisters_conference"),
    key: "display.sisters_conference",
    sortMethod: (a, b) => {
      let modifier = 1;
      if (store.sortDirection === "descending") modifier = -1;

      if (a.display.sisters_conference == null) {
          return 1 * modifier;
      }
      if (b.display.sisters_conference == null) {
          return -1 * modifier;
      }

      if (a.display.sisters_conference < b.display.sisters_conference) return -1 * modifier;
      if (a.display.sisters_conference > b.display.sisters_conference) return 1 * modifier;
      return 0;
    }
  },
  {
    text: t("overnighting.events.easter_conference"),
    key: "display.easter_conference",
    sortMethod: (a, b) => {
      let modifier = 1;
      if (store.sortDirection === "descending") modifier = -1;

      if (a.display.easter_conference == null) {
          return 1 * modifier;
      }
      if (b.display.easter_conference == null) {
          return -1 * modifier;
      }

      if (a.display.easter_conference < b.display.easter_conference) return -1 * modifier;
      if (a.display.easter_conference > b.display.easter_conference) return 1 * modifier;
      return 0;
    }
  },
  {
    text: t("overnighting.events.spring_conference"),
    key: "display.spring_conference",
    sortMethod: (a, b) => {
      let modifier = 1;
      if (store.sortDirection === "descending") modifier = -1;

      if (a.display.spring_conference == null) {
          return 1 * modifier;
      }
      if (b.display.spring_conference == null) {
          return -1 * modifier;
      }

      if (a.display.spring_conference < b.display.spring_conference) return -1 * modifier;
      if (a.display.spring_conference > b.display.spring_conference) return 1 * modifier;
      return 0;
    }
  },
]

function transformApplicationsData(data) {
  return data.map((item) => {
    const display = {};

    display.name = `${item.first_name} ${item.last_name}`;
    display.feast_weekend = item.overnighting ? item.overnighting.feast_weekend : null;
    display.brothers_conference = item.overnighting ? item.overnighting.brothers_conference : null;
    display.sisters_conference = item.overnighting ? item.overnighting.sisters_conference : null;
    display.easter_conference = item.overnighting ? item.overnighting.easter_conference : null;
    display.spring_conference = item.overnighting ? item.overnighting.spring_conference : null;

    return { ...item, display }
  })
}

const filteredApplications = computed(() => {
  return store.applications
    // Filter only next year group & no possible_start_date
    .filter(item => item.year_group == getCurrentYearGroup() || !item.year_group)
    .filter(item => {
      return (item.first_name + ' ' + item.last_name).toLowerCase().includes(store.search_name.toLowerCase())
      || (item.last_name + ' ' + item.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    })
})

onBeforeMount(async () => {
  if (directusStore.isAdmin() || directusStore.userHasRole('stedsansvarlig')) {
    await getApplications()
    await getParticipantsOvernighting()

    store.applications = transformApplicationsData(store.applications)

    if (store.applications.length == 0) {
      store.showNoApplicationsMessage = true
    }
  }
  else if (directusStore.userHasRole('applicant')) {
    await getExistingOvernighting()
  }
  else {
    router.push({ path: "/" });
  }

  store.isLoading = false
})

async function getApplications() {
  const applications = await directus.request(
    readItems('applications', {
      fields: [
        "id",
        "first_name",
        "last_name",
        "year_group"
      ],
      filter: {
        status: { _eq: "active_participation" }
      },
      limit: -1
    })
  )

  store.applications = applications
}

async function getParticipantsOvernighting() {
  const overnightings = await directus.request(
    readItems('overnighting', {
      fields: ['*'],
      limit: -1
    })
  )

  store.applications.forEach(application => {
    let thisOvernighting = overnightings.find(overnighting => overnighting.application_id == application.id)

    if (thisOvernighting) {
      application.overnighting = {
        feast_weekend: thisOvernighting.feast_weekend,
        brothers_conference: thisOvernighting.brothers_conference,
        sisters_conference: thisOvernighting.sisters_conference,
        easter_conference: thisOvernighting.easter_conference,
        spring_conference: thisOvernighting.spring_conference
      }
    }
  })
}

async function getExistingOvernighting() {
  const overnighting = await directus.request(
    readItems('overnighting', {
      filter: {
        application_id: { _eq: user.applications[user.applications.length-1] }
      },
      fields: ['*']
    })
  )

  store.overnighting.application_id = { id: user.applications[user.applications.length-1] }

  if (!overnighting.length) return

  store.overnighting.id = overnighting[0].id
  store.overnighting.events.feast_weekend = overnighting[0].feast_weekend
  store.overnighting.events.brothers_conference = overnighting[0].brothers_conference
  store.overnighting.events.sisters_conference = overnighting[0].sisters_conference
  store.overnighting.events.easter_conference = overnighting[0].easter_conference
  store.overnighting.events.spring_conference = overnighting[0].spring_conference
}

async function saveOvernighting() {
  store.isLoading = true

  let data = {
    application_id: store.overnighting.application_id.id,
    feast_weekend: store.overnighting.events.feast_weekend,
    brothers_conference: store.overnighting.events.brothers_conference,
    sisters_conference: store.overnighting.events.sisters_conference,
    easter_conference: store.overnighting.events.easter_conference,
    spring_conference: store.overnighting.events.spring_conference
  }

  if (!store.overnighting.id) {
    const response = await directus.request(createItem('overnighting', data))
    store.overnighting.id = response.id
  }
  else {
    await directus.request(updateItem('overnighting', store.overnighting.id, data))
  }

  store.isLoading = false
}

function updateSelectOptionsLists() {
  Object.entries(store.selectOptionsKeysTranslatable).map(([key, value]) => {
    store.list[key] = value.map(val => ({ 'value': val, 'label': t('overnighting.' + key + '.' + val) }))
  })
}

function getCurrentYearGroup() {
  const date = new Date()
  return date.getMonth()+1 >= 8
    ? `${(date.getFullYear()).toString().slice(-2)}/${(date.getFullYear()+1).toString().slice(-2)}`
    : `${(date.getFullYear()-1).toString().slice(-2)}/${(date.getFullYear()).toString().slice(-2)}`
}
</script>
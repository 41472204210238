<template>
    <div class="flex flex-col">
        <div class="flex mt-[-5%] max-w-[275px] justify-self-center md:max-w-[393px]">
            <div class="flex mt-[-5%] max-w-[275px] justify-self-center md:max-w-[393px]">
                <div class="flex overflow-x-auto gap-2 p-2 mb-1 whitespace-nowrap scroll-smooth touch-pan-x">
                    <BccButton class="text-black" v-for="(region) in regions" :key="region" variant="tertiary"
                        @click="setRegion(region)"
                        :class="{ 'text-[var(--silver-tree-600)]': displayStatus === region }">
                        {{ region }}
                    </BccButton>
                </div>
            </div>
        </div>

        <div class="flex-1 flex flex-col min-h-0 max-h-[calc(100vh-348px)] overflow-y-auto">
            <div v-if="dataToDisplay && dataToDisplay.length > 0" class="flex-1 ">
                <Ranking :ranking="dataToDisplay" />
            </div>
            <div v-else-if="loading">Loading ...</div>
            <div v-else class="text-red-500 p-4">
                No data available
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue';
import { useDirectusStore } from '../../stores/directus'
import { BccButton } from '@bcc-code/design-library-vue';
import Ranking from './Ranking.vue';

const directusStore = useDirectusStore()
const directus = directusStore.directus

const displayStatus = ref('Overall');
const loading = ref(true);

const regions = ['Overall', 'Norden', 'Syd', 'NordWest', 'Østlandet'];

const setRegion = (region) => {
    displayStatus.value = region
}

const data = ref([])
const dataToDisplay = ref([])

onMounted(async () => {
    displayStatus.value = 'Overall';
    data.value = await directus.request(() => ({
        path: '/aksjon/local',
        method: 'GET',
    }));
    loading.value = false;
})

watchEffect(() => {
    if (displayStatus.value) {
        if (displayStatus.value === 'Overall') {
            dataToDisplay.value = data.value
        } else {
            dataToDisplay.value = data.value.filter(x => x.team.toLowerCase() === displayStatus.value.toLowerCase())
        }
    }
})
</script>

<style scoped>
.overflow-x-auto {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.overflow-x-auto::-webkit-scrollbar {
    display: none;
}

.overflow-y-auto {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.overflow-x-auto::-webkit-scrollbar {
    display: none;
}
</style>
<template>
  <div class="grid gap-4" v-if="store.status">
    <router-link to="/" class="disable-link flex gap-2">
      <span class="text-xl leading-6 font-semibold">{{ $t('menu.myFinances') }}</span>
    </router-link>

    <p v-html="t('finance.info', { email: '<a href=\'mailto:ateam-invoice@bcc.no\'>ateam-invoice@bcc.no</a>' })"></p>

    <div class="container" v-if="isParticipant()">
      <div class="border p-3 grid gap-3 rounded-lg">
        <div class="flex-1 grid">
          <span class="text-xl md:text-2xl font-semibold">BCC A-Team</span>
          <span class="text-md md:text-lg" v-if="false">{{ t('finance.balance') }}: <span class="font-semibold">{{
            amountFormatter(store.status.balance, 'de-DE', 'EUR') }}</span></span>
        </div>

        <div class="my-2 px-4 py-4 bg-neutral-100 rounded-lg">
          <div class="text-lg md:text-xl font-semibold">{{ t('finance.oneTimePayments') }}</div>
          <ul>
            <li v-for="(prePayment, index) in store.status.prePayments" :key="index" class="flex flex-col gap-4 items-left justify-between mt-4 mb-8 md:flex-row md:items-center">
              <div class="flex gap-4 items-center">
                <BccStatusIndicator status="success" v-if="prePayment.status === 'paid'" :title="$t('finance.status.paid')" />
                <BccStatusIndicator :icon="RadioButtonPartialIcon" v-else-if="prePayment.status === 'partially'" :title="$t('finance.status.partially')" />
                <BccStatusIndicator :icon="MoreHorizIcon" v-else-if="prePayment.status === 'waiting'" :title="$t('finance.status.waiting')" />
                <BccStatusIndicator :icon="CalendarMonthIcon" v-else :title="$t('finance.status.scheduled')" />
                                              
                <div class="flex-1">{{ prePayment.title }}</div>
              </div>

              <form :action="directus.url + '/stripe-checkout/pay?token=' + store.token" method="POST" v-if="prePayment.status === 'waiting'" class="flex flex-col">
                <BccButton variant="primary" type="submit">{{ t('finance.payNow') }} <span class="font-bold ml-1">{{ amountFormatter(prePayment.amount, 'no-NO', 'NOK') }}</span></BccButton>
              </form>
            </li>

            <li class="flex flex-col gap-4 items-left justify-between mt-4 mb-2 md:flex-row md:items-center">
              <div class="flex gap-4 items-center">
                <BccStatusIndicator />
                <div class="flex-1">{{ t('finance.customAmountPayment') }}</div>
              </div>

              <form :action="directus.url + '/stripe-checkout/contribute?amount=' + store.customAmount + '&token=' + store.token" method="POST" class="flex flex-col gap-2 md:flex-row">
                <BccInput placeholder="The amount in € you want to send" type="number" step="0.01" id="custom_amount" name="custom_amount" v-model="store.customAmount" />
                <BccButton variant="primary" type="submit" :disabled="store.customAmount <= 0">{{ t('finance.payNow') }}</BccButton>
              </form>
            </li>
          </ul>
        </div>

        <template v-if="false">
          <div class="grid gap-4 my-2 px-4 py-4 bg-neutral-100 rounded-lg" v-if="store.status.budgets.length">
            <div class="text-lg md:text-xl font-semibold">{{ t('finance.monthlySubscription') }}</div>

            <form :action="directus.url + '/stripe-checkout/subscribe?token=' + store.token" method="POST" v-if="!store.status.subscription" class="hidden">
              <BccButton variant="primary" type="submit">{{ t('finance.subscribe') }}</BccButton>
            </form>

            <BccButton variant="secondary" class="mr-auto" v-else>{{ t('finance.subscribed') }}</BccButton>

            <ul>
              <li v-for="(budget, index) in store.status.budgets" :key="index" class="flex gap-4 my-4">
                <BccStatusIndicator status="success" v-if="store.status.invoices[index]?.status === 'paid'" :title="$t('finance.status.paid')" />
                <BccStatusIndicator :icon="RadioButtonPartialIcon" v-else-if="store.status.invoices[index]?.status === 'partially'" :title="$t('finance.status.partially')" />
                <BccStatusIndicator :icon="MoreHorizIcon" v-else-if="store.status.invoices[index]?.status === 'waiting'" :title="$t('finance.status.waiting')" />
                <BccStatusIndicator :icon="CalendarMonthIcon" v-else :title="$t('finance.status.scheduled')" />

                <div class="flex-1">
                  <div>
                    {{ budget.title }}: <span class="font-bold ml-1">{{ amountFormatter(budget.amount, 'de-DE', 'EUR') }}</span>
                  </div>
                  <ul class="mb-2 py-2 w-48" v-if="budget.services.length">
                    <hr class="pb-2">
                    <li v-for="(service, index) in budget.services" :key="index">
                      - {{ t('finance.services.' + service.name) }}: <span class="font-bold ml-1">{{ amountFormatter(service.amount, 'de-DE', 'EUR') }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <div v-else>{{ $t('finance.noStatus') }}</div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { amountFormatter } from '../helpers/fields.js'
import { BccInput, BccButton, BccStatusIndicator } from '@bcc-code/design-library-vue';
import { RadioButtonPartialIcon, MoreHorizIcon, CalendarMonthIcon } from '@bcc-code/icons-vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = reactive({
  token: null,
  status: null,
  customAmount: null
})

const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

onBeforeMount(async () => {
  store.token = await directus.getToken()
})

onMounted(async () => {
  store.status = await directus.request(() => ({
    path: '/stripe-checkout/status/me',
    method: 'GET'
  }))
  store.customAmount = store.status.balance < 0 ? -store.status.balance : null
})

const isParticipant = () => {
    return directusStore.userHasRole('applicant') && user.employments.length
}
</script>
import { defineStore } from "pinia";
import { createDirectus, authentication, staticToken, rest, readMe } from "@directus/sdk";
import { useAuthStore } from "./auth";

export const useDirectusStore = defineStore("directus", {
  state: () => ({
    directus: null,
    user: null,
    retryLimit: 3,
    tryCount: 0,
  }),
  getters: {
    getDirectus() {
      return this.directus;
    },

    getDirectusApiUrl() {
      if (window.location.hostname == "my.ateam.bcc.no") return "https://admin.ateam.bcc.no";
      else if (window.location.hostname == "dev.my.ateam.bcc.no") return "https://dev.admin.ateam.bcc.no";

      return "http://localhost:8055";
    },

    userHasRole: (state) => {
      return (role) => state.user.role?.slug == role;
    },
  },
  actions: {
    async setupDirectus() {
      const authStore = useAuthStore();

      const token = await authStore.getIdToken;
      if (!token) return null;

      this.directus = createDirectus(this.getDirectusApiUrl)
        .with(authentication("cookie", { credentials: "include" }))
        .with(staticToken(token))
        .with(rest({ credentials: "include" }));
    },

    async setUser() {
      if (!this.user) {
        this.user = await this.directus
          .request(
            readMe({
              fields: [
                "*",
                "role.slug",
                "administrator_for.local_churches_id.id",
                "employments.id",
                "employments.location.id",
                "employments.employer_id.id",
                "employments.employer_id.tags",
              ],
            }),
          )
          .catch(async () => {
            console.log("Try " + (this.tryCount + 1) + ":", "Failed to get the user from the database!");

            if (++this.tryCount < this.retryLimit) {
              setTimeout(async () => {
                const authStore = useAuthStore();

                await authStore.setupAuth();
                await this.setupDirectus();
                await this.setUser();
              }, 3000);
            } else {
              this.tryCount = 0;
              const errorWrapper = document.getElementById("error-wrapper");
              errorWrapper.style.display = "flex";
              errorWrapper.querySelector(".error-message").innerHTML =
                "There was an error when trying to get the user from the database!<br />You can try again by refreshing the page.<br /><br />If the problem persists, send an email to <a href='mailto:ateam@bcc.no'>ateam@bcc.no</a>.";
            }
          });
      }
    },

    isAdmin() {
      return this.userHasRole("administrator") || this.userHasRole("assistent_admin");
    },
  },
});

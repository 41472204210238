<script setup>
import { BccNpsResult } from '@bcc-code/design-library-vue';
import { onMounted, ref } from 'vue';
import { useDirectusStore } from '../../stores/directus'
import { BccInput } from '@bcc-code/design-library-vue';

const directusStore = useDirectusStore()
const directus = directusStore.directus

const score = ref(0)
const underline = ref('Loading...')
const transactions = ref([])
const error = ref(null)
const expected = ref()
const actual = ref()
const goal = ref(115);

const validateGoal = () => {
    goal.value = goal.value.toString().replace(/\D/g, "").slice(0, 3); // Remove non-digits, limit to 3 characters
};

const onlyDigits = (event) => {
    if (!/^\d$/.test(event.key)) {
        event.preventDefault(); // Prevent non-numeric input
    }
};
onMounted(async () => {
    await getPersonalStatus();
})

function getScore(expectedSavings, actualSavings) {
    expected.value = parseFloat(expectedSavings.replace(/,/g, '.')) || 0;
    actual.value = parseFloat(actualSavings.replace(/,/g, '.')) || 0;
    return expected ? Math.round(actual.value / expected.value * 100) : 0;
}

function getUnderline(expectedSavings, actualSavings) {
    return `${actualSavings || 0} / ${expectedSavings || 0}`;
}

async function getPersonalStatus() {
    try {
        const data = await directus.request(() => ({
            path: '/aksjon/personal',
            method: 'GET',
        }))
        if (!data) {
            error.value = "No data available";
            return;
        }
        score.value = getScore(data.expectedSavings, data.actualSavings);
        underline.value = getUnderline(data.expectedSavings, data.actualSavings);
        transactions.value = data.transactions || [];
    } catch (err) {
        error.value = "Failed to load data";
        console.error(err);
    }
}
</script>

<template>
    <div class="mt-[5%] w-[250px] md:w-[375px]">
        <div class="w-[90%] ml-[5%]">
            <BccNpsResult :score="score" :underline="underline" :display="score + '%'"></BccNpsResult>
        </div>
        <div v-if="!error && transactions.length > 0" class="mt-4">
            <h2 class="text-lg font-bold mb-4">Innbetalt denne måneden</h2>
            <!-- <div v-for="transaction in transactions" class="flex justify-between py-2 border-b border-gray-200">
                <p class="w-[12%]">{{ transaction.month }}</p>
                <p class="">{{ transaction.transaction }}</p>
            </div> -->
            <div class="flex justify-between py-2 border-b border-gray-200 mt-4">
                <p class="w-[12%]"><b>{{ transactions[transactions.length - 1].month }}</b></p>
                <p class="">{{ transactions[transactions.length - 1].transaction }} NOK</p>
            </div>
            <!-- <div class="flex flex-col mt-8 items-center">
                <h2>Mål kalkulator</h2>
                <div class="flex mt-2 items-center">
                    <p class="mr-1">Mitt mål er</p>
                    <BccInput class="max-w-[62px]" :size="sm" v-model="goal" @input="validateGoal"
                        @keypress="onlyDigits" />%
                </div>
                <div class="mt-4">
                    {{ goal }}% i {{ transactions[transactions.length - 1].month }} er {{ Math.round(expected / 100 * goal * 1000) }}NOK
                </div>
                <div v-if="score < goal" class="mt-2">For å komme til {{ goal }}% må du innbetale {{ Math.round((expected / 100 * goal * 1000) -
                    (actual * 1000)) }}NOK</div>
            </div> -->
        </div>
        <div v-else class="text-red-500 p-4">
            {{ error }}
        </div>
    </div>
</template>

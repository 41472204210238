<template>
    <div class="container h-full relative">
        <div class="grid gap-8" v-if="!store.formLoading">
            <router-link to="/" class="flex gap-2">
                <KeyboardArrowLeftIcon class="h-6" />
                <span class="text-lg leading-6 font-semibold">{{ store.currentPage.title }}</span>
            </router-link>
            <div v-html="store.currentPage.content" class="whitespace-pre-wrap"></div>

            <form @submit.prevent="handleForm" class="grid gap-4 my-8" v-if="user.applications.length">
                <div class="grid gap-8 mb-8">
                    <div class="grid gap-2">
                        <p class="font-semibold">{{ t('form.needsTransportation.title') }}:</p>
                        <em class="italic text-sm -mt-1 opacity-80 block">{{ t('form.needsTransportation.note') }}</em>
                        <BccRadio :label="t('form.yes')" value="yes" v-model="store.needs_transportation" />
                        <BccRadio :label="t('form.no')" value="no" v-model="store.needs_transportation" />
                        <BccRadio :label="t('form.dont_know')" value="dont_know" v-model="store.needs_transportation" />
                    </div>

                    <div class="grid gap-2">
                        <p class="font-semibold">{{ t('form.event_participation.title') }}:</p>
                        <BccRadio :label="t('form.event_participation.physically')" value="physically" v-model="store.event_participation" />
                        <BccRadio :label="t('form.event_participation.digitally')" value="digitally" v-model="store.event_participation" />
                        <BccRadio :label="t('form.event_participation.cannot')" value="cannot" v-model="store.event_participation" />
                        <BccRadio :label="t('form.event_participation.dont_know')" value="dont_know" v-model="store.event_participation" />
                    </div>

                    <div class="grid gap-2">
                        <p class="font-semibold">{{ t('form.needsTranslation') }}:</p>
                        <BccRadio :label="t('form.yes')" value="yes" v-model="store.needs_translation" />
                        <BccRadio :label="t('form.no')" value="no" v-model="store.needs_translation" />
                    </div>

                    <BccAlert context="danger" icon v-show="store.updateFailed" class="col-span-full">{{ t('form.errors.participationSaveFailed') }}</BccAlert>

                    <BccButton variant="primary" class="mr-auto" :disabled="store.formLoading" :class="{ 'cursor-not-allowed': store.formLoading }" type="submit">
                        <div class="spinner" :class="{ loading: store.formLoading }"></div>
                        {{ t('form.save') }}
                    </BccButton>
                </div>
            </form>
        </div>
        <div v-else class="flex h-full justify-center items-center">
            <BccSpinner size="xs" />
        </div>
    </div>
</template>
  
<script setup>
import { onBeforeMount, reactive, watch } from 'vue'
import { useDirectusStore } from '../stores/directus'
import { readItems, updateItem } from '@directus/sdk';
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { BccSpinner, BccRadio, BccAlert, BccButton } from '@bcc-code/design-library-vue';
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'

const route = useRoute()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const store = reactive({
    currentPage: null,
    currentApplication: null,
    pages: [],
    needs_transportation: null,
    event_participation: null,
    needs_translation: null,
    updateFailed: false,
    formLoading: true
})

onBeforeMount(async () => {
    await getPage()
    store.currentApplication = await getCurrentApplication()
    setParticipationChoices()
    store.formLoading = false
})

watch(
    route,
    () => changePage()
)

async function getPage() {
    // Reset page content
    store.currentPage = null

    let pageFilter = {
        type: { _eq: 'event' }
    }

    if (!directusStore.isAdmin()) {
        pageFilter = { ...pageFilter, status: { _eq: 'published' } }
    }

    const pages = await directus.request(
        readItems('pages', {
            filter: pageFilter,
            fields: ['*']
        })
    )
    if (!pages.length) return

    if (isParticipant()) {
        let currentOrComingEmployment = user.current_employment
            ? user.employments.find(employment => employment.id == user.current_employment.key)
            : user.employments[0] // Get the first employment

        pages.forEach(page => {
            if (page.targeted_locations.length && !page.targeted_locations.includes(currentOrComingEmployment.location.id))
                return
            
            store.pages.push(page)
        })
    }
    else {
        store.pages = pages
    }

    store.currentPage = store.pages.find(page => page.slug == route.params.slug)
}

async function getCurrentApplication() {
    if (!user.applications.length) return
    
    const applications = await directus.request(
        readItems('applications', {
            filter: {
                id: { _eq: user.applications[user.applications.length-1] }
            },
            fields: ['id', 'kickoff_needs_transportation', 'kickoff_event_participation', 'kickoff_needs_translation']
        })
    )

    if (!applications.length) return

    return applications[0]
}

function setParticipationChoices() {
    if (!store.currentApplication) return
  
    store.needs_transportation = store.currentApplication.kickoff_needs_transportation
    store.event_participation = store.currentApplication.kickoff_event_participation
    store.needs_translation = store.currentApplication.kickoff_needs_translation
}

async function handleForm() {
    store.formLoading = true

  const data = {
    kickoff_needs_transportation: store.needs_transportation,
    kickoff_event_participation: store.event_participation,
    kickoff_needs_translation: store.needs_translation,
  }

  if (!store.currentApplication) return

  await saveEventParticipation(data)
  store.formLoading = false
}

async function saveEventParticipation(data) {
  try {
    await directus.request(updateItem('applications', store.currentApplication.id, data))
  }
  catch (error) {
    store.updateFailed = true
    console.error("There was an error!", error)
  }
}

function changePage() {
    store.currentPage = store.pages.find(page => page.slug == route.params.slug)
}

const isParticipant = () => {
    return directusStore.userHasRole('applicant') && user.employments.length
}
</script>

<style>
    .whitespace-pre-wrap img {
        display: inline-block;
    }

    .whitespace-pre-wrap ul {
        list-style-type: disc;
        list-style-position: inside;
        line-height: normal;
    }

    .whitespace-pre-wrap tbody tr td:first-child {
        vertical-align: top;
        padding-top: 20px;
    }

    @media screen and (max-width: 480px) {
        .whitespace-pre-wrap colgroup col {
            width: 100% !important;
        }

        .whitespace-pre-wrap tbody,
        .whitespace-pre-wrap tr {
            display: flex;
            flex-direction: column;
        }
    }
</style>
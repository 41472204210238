<template>
  <div v-if="user" class="grid gap-8">

    <router-link to="/dashboard/language-classes" class="flex gap-2">
      <KeyboardArrowLeftIcon class="h-6" />
      <span class="text-lg leading-6 font-semibold">{{ $t('languageClass.title') }}</span>
    </router-link>

    <div v-if="!directusStore.userHasRole('applicant')">
      <form @submit.prevent="submitParticipation">
        <div class="grid gap-8">
          <div>
            <BccInput v-model="store.participationDate" id="participationDate" class="mb-2 w-1/2" :placeholder="'Ex: ' + getCurrentDate()"
              type="text" autocomplete="off" />

            <button type="button" @click="chooseCurrentDate()" class="block italic hover:underline">{{
              t('form.chooseCurrentDate') }}</button>
          </div>

          <div>
            <button type="button" @click="checkAllParticipants()" class="checkAll underline"
              v-if="!allParticipantsChecked()">{{ t('form.selectAll') }}</button>
            <button type="button" @click="uncheckAllParticipants()" class="uncheckAll underline"
              v-if="allParticipantsChecked()">{{ t('form.removeAll') }}</button>

            <div class="flex flex-col gap-2 mt-2">
              <div v-for="application in store.applications" :key="application.created_by">
                <BccCheckbox :id="'application-' + application.id" name="is_participating"
                    v-model="application.is_participating" :label="`${application.first_name} ${application.last_name}`" />
              </div>
            </div>
          </div>

          <BccButton :disabled="store.formLoading" class="mr-auto" :class="{ 'cursor-not-allowed': store.formLoading }" type="submit">
            <div class="spinner" :class="{ loading: store.formLoading }"></div>
            {{ t('form.submit') }}
          </BccButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { readItems, createItem, updateItem } from '@directus/sdk';
import { useRoute, useRouter } from 'vue-router'
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'
import { BccButton, BccInput, BccCheckbox } from '@bcc-code/design-library-vue'

import { getCurrentDate } from '../helpers/fields.js'

import Pikaday from "pikaday"
import 'pikaday/css/pikaday.css'
import 'pikaday/css/triangle.css'

const route = useRoute()
const router = useRouter()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = reactive({
  languageClass: null,
  applications: [],
  participationDate: null,
  dateInputs: {},
  formLoading: false
})

onBeforeMount(async () => {
  await getLanguageClass()
  await getApplications()
})

onMounted(async () => {
  defineDataInput('participationDate', { maxDate: new Date() })
})

function defineDataInput(id, extraFields = {}) {
  store.dateInputs[id] = new Pikaday({
    field: document.getElementById(id),
    theme: 'triangle-theme',
    firstDay: 1,
    i18n: {
      "previousMonth": t('previousMonth'),
      "nextMonth": t('nextMonth'),
      "months": [t('months.1'), t('months.2'), t('months.3'), t('months.4'), t('months.5'), t('months.6'), t('months.7'), t('months.8'), t('months.9'), t('months.10'), t('months.11'), t('months.12')],
      "weekdays": [t('weekdays.7'), t('weekdays.1'), t('weekdays.2'), t('weekdays.3'), t('weekdays.4'), t('weekdays.5'), t('weekdays.6')],
      "weekdaysShort": [t('weekdaysShort.7'), t('weekdaysShort.1'), t('weekdaysShort.2'), t('weekdaysShort.3'), t('weekdaysShort.4'), t('weekdaysShort.5'), t('weekdaysShort.6')]
    },
    onSelect: function () {
      store[id] = this.toString('YYYY-MM-DD')
    },
    ...extraFields
  })
}

async function getLanguageClass() {
  if (route.params.id == '+') return

  const languageClass = await directus.request(
    readItems('norwegian_class_participations', {
      filter: {
        id: { _eq: route.params.id }
      },
      fields: ['*', 'user_created.*']
    })
  )
  if (!languageClass.length) return

  store.languageClass = languageClass[0]
  store.participationDate = store.languageClass.date
}

async function getApplications() {
  const applications = await directus.request(
    readItems('applications', { limit: -1 })
  )
  if (!applications.length) return

  store.applications = applications
  store.applications.forEach(application => {
    application.is_participating = !!store.languageClass?.participation
      .filter(languageClass =>
        languageClass.participant.key == application.created_by
        && languageClass.participated
      )
      .length
  })
}

function chooseCurrentDate() {
  store.participationDate = getCurrentDate()
}

function checkAllParticipants() {
  store.applications.forEach(languageClass => languageClass.is_participating = true)
}

function uncheckAllParticipants() {
  store.applications.forEach(languageClass => languageClass.is_participating = false)
}

function allParticipantsChecked() {
  return store.applications.length == store.applications.filter(languageClass => languageClass.is_participating).length
}

async function submitParticipation() {
  let participations = []

  store.applications.forEach(languageClass => {
    participations.push({
      "participant": {
        "key": languageClass.created_by,
        "collection": "directus_users"
      },
      "participated": !!languageClass.is_participating
    })
  })

  const data = {
    date: store.participationDate,
    participation: participations
  }

  !store.languageClass
    ? await directus.request(createItem('norwegian_class_participations', data))
    : await directus.request(updateItem('norwegian_class_participations', store.languageClass.id, data))

  router.push({ path: '/dashboard/language-classes' })
}
</script>
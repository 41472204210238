<template>
  <div id="error-wrapper" class="w-full absolute flex-col bg-white p-4 gap-4 md:justify-center md:items-center hidden">
    <h1 class="font-bold text-2xl md:text-3xl text-dark-green-900">Error!</h1>
    <p class="error-message"></p>
  </div>
</template>

<script setup></script>

<style>
  #error-wrapper {
    top: 64px;
    height: calc(100% - 64px);
  }
</style>

<template>
  <div v-if="user" class="grid gap-8">

    <router-link :to="'/dashboard/participants/' + store.employment?.user_id" class="flex gap-2">
      <KeyboardArrowLeftIcon class="h-6" />
      <span class="text-lg leading-6 font-semibold">{{ $t('employment.backToParticipant') }}</span>
    </router-link>

    <h1 class="text-xl leading-6 font-semibold">{{ $t('employment.title') }}</h1>

    <form @submit.prevent="updateEmployment" v-if="store.employment">
      <FormSection columns="2" class="!p-0">
        <BccInput :label="t('dashboard.location')" v-model="store.employment.location.name"
          type="text" autocomplete="off" disabled />

        <BccInput :label="t('dashboard.current_employer')" v-model="store.employment.employer_id.legal_entity_name"
          type="text" autocomplete="off" disabled />

        <BccInput :label="t('dashboard.arrival_date')" v-model="store.employment.arrival_date"
          id="arrival_date" type="text" autocomplete="off" />

        <BccInput :label="t('dashboard.departure_date')" v-model="store.employment.departure_date"
          id="departure_date" type="text" autocomplete="off" />

        <BccInput :label="t('dashboard.employment_start_date')" v-model="store.employment.date_start"
          id="date_start" type="text" autocomplete="off" />

        <BccInput :label="t('dashboard.employment_end_date')" v-model="store.employment.date_end"
          id="date_end" type="text" autocomplete="off" />

        <BccButton :disabled="store.formLoading" class="mr-auto" :class="{ 'cursor-not-allowed': store.formLoading }" type="submit">
          <div class="spinner" :class="{ loading: store.formLoading }"></div>
          {{ t('form.save') }}
        </BccButton>

      </FormSection>
    </form>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus.js'
import { readItems, updateItem } from '@directus/sdk';
import { useRoute } from 'vue-router'
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'
import { BccButton, BccInput } from '@bcc-code/design-library-vue'

import FormSection from '../components/FormSection.vue'

import Pikaday from "pikaday"
import 'pikaday/css/pikaday.css'
import 'pikaday/css/triangle.css'

const route = useRoute()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = reactive({
  employment: null,
  dateInputs: {},
  formLoading: false
})

onMounted(async () => {
  await getEmployment()

  defineDataInput('arrival_date')
  defineDataInput('departure_date')
  defineDataInput('date_start')
  defineDataInput('date_end')
})

async function getEmployment() {
  const employment = await directus.request(
    readItems('employments', {
      filter: {
        id: { _eq: route.params.id }
      },
      fields: ['*', 'location.name', 'employer_id.legal_entity_name']
    })
  )
  if (!employment.length) return

  store.employment = employment[0]
}

async function updateEmployment() {
  const data = {
    'arrival_date': store.employment.arrival_date,
    'departure_date': store.employment.departure_date,
    'date_start': store.employment.date_start,
    'date_end': store.employment.date_end
  }

  store.formLoading = true

  try {
    await directus.request(updateItem('employments', store.employment.id, data))
  }
  catch (error) {
    console.error("There was an error!", error)
  }

  store.formLoading = false

  location.reload()
}

function defineDataInput(id, extraFields = {}) {
  store.dateInputs[id] = new Pikaday({
    field: document.getElementById(id),
    theme: 'triangle-theme',
    firstDay: 1,
    i18n: {
      "previousMonth": t('previousMonth'),
      "nextMonth": t('nextMonth'),
      "months": [t('months.1'), t('months.2'), t('months.3'), t('months.4'), t('months.5'), t('months.6'), t('months.7'), t('months.8'), t('months.9'), t('months.10'), t('months.11'), t('months.12')],
      "weekdays": [t('weekdays.7'), t('weekdays.1'), t('weekdays.2'), t('weekdays.3'), t('weekdays.4'), t('weekdays.5'), t('weekdays.6')],
      "weekdaysShort": [t('weekdaysShort.7'), t('weekdaysShort.1'), t('weekdaysShort.2'), t('weekdaysShort.3'), t('weekdaysShort.4'), t('weekdaysShort.5'), t('weekdaysShort.6')]
    },
    onSelect: function () {
      store.employment[id] = this.toString('YYYY-MM-DD')
    },
    ...extraFields
  })
}
</script>
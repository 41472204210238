<script setup>
import { ref } from 'vue';
import { PersonIcon, LocationOnIcon, GroupsIcon } from '@bcc-code/icons-vue';
import PersonalStatus from '../components/aksjon/PersonalStatus.vue';
import LocalStatus from '../components/aksjon/LocalStatus.vue';
import TeamStatus from '../components/aksjon/TeamStatus.vue';
import { useDirectusStore } from "../stores/directus.js";

const displayStatus = ref('local');
const directusStore = useDirectusStore()
const user = directusStore.user

const statusOptions = [
  { name: 'personal', icon: PersonIcon, label: 'Personal' },
  { name: 'local', icon: LocationOnIcon, label: 'Local' },
  { name: 'team', icon: GroupsIcon, label: 'Team' },
];

const setStatus = (status) => {
  displayStatus.value = status;
};
</script>

<template>
  <template
    v-if="directusStore.isAdmin() || directusStore.userHasRole('assistent_admin') || directusStore.userHasRole('stedsansvarlig') || directusStore.userHasRole('applicant') && user.employments.length">
    <div class="flex flex-col items-center text-center overscroll-none mx-auto max-w-[calc(100vw-4.5rem-8px)] h-full  max-h-[100vh-112px]">
      <img class="mt-5 mb-10 max-w-[210px]" src="../assets/pace_logo_black.png" />
      <div class="h-full">
        <PersonalStatus v-if="displayStatus === 'personal'" />
        <LocalStatus v-if="displayStatus === 'local'" />
        <TeamStatus v-if="displayStatus === 'team'" />
      </div>
      <div class="bottom-0 h-12 w-full md:max-w-[393px]">
        <div class="flex justify-between h-full bg-white">
          <div v-for="(status, index) in statusOptions" :key="index"
            class="flex flex-col p-0 cursor-pointer rounded-2xl z-10 w-[90%]"
            :class="{ 'text-[var(--silver-tree-600)]': displayStatus === status.name }" @click="setStatus(status.name)">
            <component :is="status.icon" class="min-h-8 min-w-8" />
            <p class="text-xs font-semibold">{{ status.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <p>You don't have permission to view this page</p>
  </template>
</template>

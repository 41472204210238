<template>
    <div class="grid gap-8">
        <router-link to="/dashboard/participants" class="flex gap-2 print:hidden">
            <KeyboardArrowLeftIcon class="h-6" />
            <span class="text-lg leading-6 font-semibold">{{ $t('dashboard.actions.seeProfile') }}</span>
        </router-link>

        <div v-if="store.application" class="container">
            <div v-if="!directusStore.userHasRole('applicant')" class="grid gap-4">
                <div class="grid grid-cols-[1fr_auto] gap-2 md:gap-4">
                    <div>
                        <div class="font-bold text-2xl text-dark-green-900">{{ store.application.first_name }} {{
                            store.application.last_name }}</div>
                        <div class="text-dark-green-100 print:hidden">BCC {{ store.application.local_church?.name }}
                        </div>

                        <div class="mt-5 print:hidden"
                            v-if="store.application.employment_comment && directusStore.isAdmin()">
                            <p class="font-bold mb-1">{{ t('interest.employment_comment') }}</p>
                            <div v-html="store.application.employment_comment"></div>
                        </div>
                    </div>

                    <div v-if="store.application.profile_picture" class="print:absolute print:right-0 print:top-0">
                        <img :src="directus.url.origin + '/assets/' + store.application.profile_picture.id + '?access_token=' + store.access_token"
                            class="rounded-lg" width="150" />
                    </div>
                </div>

                <BccTabs class="quick-fix_bcc-tabs" :tabs="[
                    { title: t('form.profile'), icon: PersonIcon }
                ]">
                    <template #tab-1>
                        <div class="py-4 print:relative hide-bg-print">
                            <BccTable :columns="employmentsColumns()" :items="sortedEmployments" v-model:sortBy="store.sortByForEmployments"
                                v-model:sortDirection="store.sortDirectionForEmployments" class="max-w-full overflow-x-auto quick-fix_white-space" v-if="sortedEmployments.length">

                                <template #item.actions="{ item }">
                                    <BccButton variant="tertiary" size="sm" :padding="false" :icon="ChevronRightIcon" iconRight
                                        @click="editEmployment(item)">{{ t('dashboard.actions.edit') }}</BccButton>
                                </template>

                            </BccTable>

                            <div class="px-4">
                                <FormSection class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.gender.title')">
                                        <span>{{ t('form.gender.' + store.application.gender) }}</span>
                                    </FormFieldResult>

                                    <div></div>

                                    <FormFieldResult :label="t('form.first_name.title')">
                                        <span>{{ store.application.first_name || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.last_name.title')">
                                        <span>{{ store.application.last_name || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.address.title')">
                                        <span>{{ store.application.address || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.postal_code.title')">
                                        <span>{{ store.application.postal_code || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.city.title')">
                                        <span>{{ store.application.city || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.country.title')">
                                        <span>{{ store.application.country || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.birth_date.title')">
                                        <span>{{ store.application.birth_date ? getFormattedDate(store.application.birth_date) :
                                            '-' }} </span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.birth_place.title')">
                                        <span>{{ store.application.birth_place || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.country_code.title')">
                                        <span>{{ store.application.country_code || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.phone_number.title')">
                                        <span>{{ store.application.phone_number || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.email_address.title')">
                                        <span>{{ store.application.email_address || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('dashboard.local_church')" class="print:hidden">
                                        <span>{{ store.application.local_church?.name || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.personal_info')" columns="2">
                                    <FormFieldResult :label="t('form.motivation_and_expectation.title')">
                                        <span>{{ store.application.motivation_and_expectation || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.personal_description.title')">
                                        <span>{{ store.application.personal_description || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.hobbies.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.hobbies" :key="index">{{
                                            t('form.hobbies.' + value) }}</span>
                                        <span v-if="!store.application.hobbies?.length">-</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.preferable_tasks_in_local_church.title')">
                                        <span>{{ store.application.preferable_tasks_in_local_church || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.work_experience')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.full_time_job.title')">
                                        <span>{{ store.application.full_time_job ? t('form.yesOrNo.' + store.application.full_time_job) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.areasOfKnowledge.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.area_of_knowledge" :key="index">{{
                                            t('form.areasOfKnowledge.' + value) }}</span>
                                        <span v-if="!store.application.area_of_knowledge?.length">-</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.work_years_of_experience.title')" class="col-span-full">
                                        <span>{{ store.application.work_years_of_experience || '-' }}</span>
                                    </FormFieldResult>

                                    <label class="bcc-form-label col-span-full">{{ t('form.work_reference_person.title') }}</label>

                                    <label class="bcc-form-label col-span-full">{{ t('form.work_reference_person.reference') }} 1</label>
                                    <FormFieldResult :label="t('form.work_reference_person.name')">
                                        <span>{{ store.application.work_reference_person1_name || '-' }}</span>
                                    </FormFieldResult>
                                    <FormFieldResult :label="t('form.work_reference_person.phone_number')">
                                        <span>{{ store.application.work_reference_person1_phone_number || '-' }}</span>
                                    </FormFieldResult>
                                    <FormFieldResult :label="t('form.work_reference_person.email_address')">
                                        <span>{{ store.application.work_reference_person1_email_address || '-' }}</span>
                                    </FormFieldResult>
                                    <FormFieldResult :label="t('form.work_reference_person.work_type')">
                                        <span>{{ store.application.work_reference_person1_work_type || '-' }}</span>
                                    </FormFieldResult>

                                    <label class="bcc-form-label col-span-full">{{ t('form.work_reference_person.reference') }} 2</label>
                                    <FormFieldResult :label="t('form.work_reference_person.name')">
                                        <span>{{ store.application.work_reference_person2_name || '-' }}</span>
                                    </FormFieldResult>
                                    <FormFieldResult :label="t('form.work_reference_person.phone_number')">
                                        <span>{{ store.application.work_reference_person2_phone_number || '-' }}</span>
                                    </FormFieldResult>
                                    <FormFieldResult :label="t('form.work_reference_person.email_address')">
                                        <span>{{ store.application.work_reference_person2_email_address || '-' }}</span>
                                    </FormFieldResult>
                                    <FormFieldResult :label="t('form.work_reference_person.work_type')">
                                        <span>{{ store.application.work_reference_person2_work_type || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.knowledgeExperienceAreas.title')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.knowledgeExperienceAreas.subtitle')" class="col-span-full print:!hidden">
                                        <div class="relative col-span-full !mt-4">
                                            <table class="w-full text-left">
                                                <thead class="sticky top-0 z-[3]">
                                                    <tr>
                                                        <th class="p-4 min-w-[200px] sticky left-0 z-[3] bg-isbre-700 rounded-tl-lg"
                                                            scope="col">{{ t('form.knowledgeExperienceAreas.area') }} / {{
                                                                t('form.knowledgeExperienceAreas.years') }}</th>
                                                        <th class="p-4 min-w-[50px] bg-isbre-700 text-center" scope="col"
                                                            v-for="index in 5" :key="index">{{ index }}</th>
                                                        <th class="p-4 min-w-[200px] bg-isbre-700 rounded-tr-lg text-center"
                                                            scope="col">{{ t('form.knowledgeExperienceAreas.interest') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="relative z-[1]">
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.kindergarten_experience || store.application.groundwork_landscaping_experience || store.application.first_aid_experience || store.application.car_detailing_experience || store.application.stocktaking_experience || store.application.pruefservice_experience || store.application.cleaning_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.diverse') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.kindergarten_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.kindergarten_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="kindergarten_experience" :value="index" v-model="store.application.kindergarten_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="kindergarten_interest" v-model="store.application.kindergarten_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.groundwork_landscaping_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.groundwork_landscaping_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="groundwork_landscaping_experience" :value="index" v-model="store.application.groundwork_landscaping_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="groundwork_landscaping_interest" v-model="store.application.groundwork_landscaping_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.first_aid_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.first_aid_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="first_aid_experience" :value="index" v-model="store.application.first_aid_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="first_aid_interest" v-model="store.application.first_aid_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.car_detailing_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.car_detailing_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="car_detailing_experience" :value="index" v-model="store.application.car_detailing_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="car_detailing_interest" v-model="store.application.car_detailing_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.stocktaking_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.stocktaking_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="stocktaking_experience" :value="index" v-model="store.application.stocktaking_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="stocktaking_interest" v-model="store.application.stocktaking_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.pruefservice_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.pruefservice_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="pruefservice_experience" :value="index" v-model="store.application.pruefservice_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="pruefservice_interest" v-model="store.application.pruefservice_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.cleaning_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.cleaning_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="cleaning_experience" :value="index" v-model="store.application.cleaning_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="cleaning_interest" v-model="store.application.cleaning_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.accounting_experience || store.application.logistics_experience || store.application.event_arrangement_leadership_experience || store.application.office_administration_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.office_work') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.accounting_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.accounting_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="accounting_experience" :value="index" v-model="store.application.accounting_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="accounting_interest" v-model="store.application.accounting_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.logistics_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.logistics_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="logistics_experience" :value="index" v-model="store.application.logistics_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="logistics_interest" v-model="store.application.logistics_interest" />
                                                        </td>
                                                    </tr>                                                
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.event_arrangement_leadership_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.event_arrangement_leadership_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="event_arrangement_leadership_experience" :value="index" v-model="store.application.event_arrangement_leadership_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="event_arrangement_leadership_interest" v-model="store.application.event_arrangement_leadership_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.office_administration_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.office_administration_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="office_administration_experience" :value="index" v-model="store.application.office_administration_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="office_administration_interest" v-model="store.application.office_administration_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.construction_installation_experience || store.application.ventilation_installation_experience || store.application.glass_railing_installation_experience || store.application.plumbing_experience || store.application.electrician_experience || store.application.automatician_low_voltage_fitter_experience || store.application.industrial_electricity_textile_production_experience || store.application.skilled_worker_concrete_experience || store.application.machine_operator_experience || store.application.carpenter_woodworking_with_trade_certificate_experience || store.application.carpenter_woodworking_assistant_experience || store.application.cabinet_maker_experience || store.application.painting_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.construction') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.construction_installation_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.construction_installation_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="construction_installation_experience" :value="index" v-model="store.application.construction_installation_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="construction_installation_interest" v-model="store.application.construction_installation_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.ventilation_installation_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.ventilation_installation_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="ventilation_installation_experience" :value="index" v-model="store.application.ventilation_installation_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="ventilation_installation_interest" v-model="store.application.ventilation_installation_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.glass_railing_installation_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.glass_railing_installation_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="glass_railing_installation_experience" :value="index" v-model="store.application.glass_railing_installation_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="glass_railing_installation_interest" v-model="store.application.glass_railing_installation_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.plumbing_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.plumbing_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="plumbing_experience" :value="index" v-model="store.application.plumbing_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="plumbing_interest" v-model="store.application.plumbing_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.electrician_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.electrician_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="electrician_experience" :value="index" v-model="store.application.electrician_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="electrician_interest" v-model="store.application.electrician_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.automatician_low_voltage_fitter_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.automatician_low_voltage_fitter_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="automatician_low_voltage_fitter_experience" :value="index" v-model="store.application.automatician_low_voltage_fitter_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="automatician_low_voltage_fitter_interest" v-model="store.application.automatician_low_voltage_fitter_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.industrial_electricity_textile_production_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.industrial_electricity_textile_production_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="industrial_electricity_textile_production_experience" :value="index" v-model="store.application.industrial_electricity_textile_production_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="industrial_electricity_textile_production_interest" v-model="store.application.industrial_electricity_textile_production_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.skilled_worker_concrete_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.skilled_worker_concrete_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="skilled_worker_concrete_experience" :value="index" v-model="store.application.skilled_worker_concrete_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="skilled_worker_concrete_interest" v-model="store.application.skilled_worker_concrete_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.machine_operator_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.machine_operator_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="machine_operator_experience" :value="index" v-model="store.application.machine_operator_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="machine_operator_interest" v-model="store.application.machine_operator_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.carpenter_woodworking_with_trade_certificate_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.carpenter_woodworking_with_trade_certificate_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="carpenter_woodworking_with_trade_certificate_experience" :value="index" v-model="store.application.carpenter_woodworking_with_trade_certificate_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="carpenter_woodworking_with_trade_certificate_interest" v-model="store.application.carpenter_woodworking_with_trade_certificate_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.carpenter_woodworking_assistant_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.carpenter_woodworking_assistant_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="carpenter_woodworking_assistant_experience" :value="index" v-model="store.application.carpenter_woodworking_assistant_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="carpenter_woodworking_assistant_interest" v-model="store.application.carpenter_woodworking_assistant_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.cabinet_maker_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.cabinet_maker_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="cabinet_maker_experience" :value="index" v-model="store.application.cabinet_maker_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="cabinet_maker_interest" v-model="store.application.cabinet_maker_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.painting_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.painting_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="painting_experience" :value="index" v-model="store.application.painting_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="painting_interest" v-model="store.application.painting_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.cooking_experience || store.application.waiter_catering_experience || store.application.production_of_bakery_products_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.cooking') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.cooking_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.cooking_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="cooking_experience" :value="index" v-model="store.application.cooking_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="cooking_interest" v-model="store.application.cooking_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.waiter_catering_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.waiter_catering_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="waiter_catering_experience" :value="index" v-model="store.application.waiter_catering_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="waiter_catering_interest" v-model="store.application.waiter_catering_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.production_of_bakery_products_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.production_of_bakery_products_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="production_of_bakery_products_experience" :value="index" v-model="store.application.production_of_bakery_products_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="production_of_bakery_products_interest" v-model="store.application.production_of_bakery_products_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.composing_experience || store.application.electronic_music_experience || store.application.other_music_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.music') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.composing_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.composing_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="composing_experience" :value="index" v-model="store.application.composing_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="composing_interest" v-model="store.application.composing_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.electronic_music_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.electronic_music_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="electronic_music_experience" :value="index" v-model="store.application.electronic_music_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="electronic_music_interest" v-model="store.application.electronic_music_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.other_music_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.other_music_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="other_music_experience" :value="index" v-model="store.application.other_music_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="other_music_interest" v-model="store.application.other_music_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.helpdesk_experience || store.application.software_development_experience || store.application.ux_designer_experience || store.application.websites_experience || store.application.other_it_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.it') }}
                                                        </td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.helpdesk_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.helpdesk_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="helpdesk_experience" :value="index" v-model="store.application.helpdesk_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="helpdesk_interest" v-model="store.application.helpdesk_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.software_development_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.software_development_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="software_development_experience" :value="index" v-model="store.application.software_development_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="software_development_interest" v-model="store.application.software_development_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.ux_designer_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.ux_designer_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="ux_designer_experience" :value="index" v-model="store.application.ux_designer_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="ux_designer_interest" v-model="store.application.ux_designer_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.websites_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.websites_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="websites_experience" :value="index" v-model="store.application.websites_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="websites_interest" v-model="store.application.websites_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.other_it_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.other_it_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="other_it_experience" :value="index" v-model="store.application.other_it_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="other_it_interest" v-model="store.application.other_it_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.design_experience || store.application.film_production_experience || store.application.film_editing_experience || store.application.animation_experience || store.application.other_media_film_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.media_film') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.design_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.design_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="design_experience" :value="index" v-model="store.application.design_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="design_interest" v-model="store.application.design_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.film_production_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.film_production_experience') }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="film_production_experience" :value="index" v-model="store.application.film_production_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="film_production_interest" v-model="store.application.film_production_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.film_editing_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.film_editing_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="film_editing_experience" :value="index" v-model="store.application.film_editing_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="film_editing_interest" v-model="store.application.film_editing_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.animation_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.animation_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="animation_experience" :value="index" v-model="store.application.animation_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="animation_interest" v-model="store.application.animation_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.other_media_film_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.other_media_film_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="other_media_film_experience" :value="index" v-model="store.application.other_media_film_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="other_media_film_interest" v-model="store.application.other_media_film_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.sound_experience || store.application.light_experience || store.application.graphics_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                            {{ t('form.knowledgeExperienceAreas.llb') }}</td>
                                                        <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.sound_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.sound_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="sound_experience" :value="index" v-model="store.application.sound_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="sound_interest" v-model="store.application.sound_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.light_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.light_experience')
                                                            }}</td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="light_experience" :value="index" v-model="store.application.light_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name=" light_interest" v-model="store.application.light_interest" />
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b border-neutral-300" v-if="store.application.graphics_experience">
                                                        <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                            scope="row">{{ t('form.knowledgeExperienceAreas.graphics_experience') }}
                                                        </td>
                                                        <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                            <BccRadio disabled name="graphics_experience" :value="index" v-model="store.application.graphics_experience" />
                                                        </td>
                                                        <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                            <BccCheckbox disabled name="graphics_interest" v-model="store.application.graphics_interest" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.education')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.school_level.title')">
                                        <span>{{ store.application.school_level ? t('form.schoolLevels.' + store.application.school_level) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.school_level_on_start.title')">
                                        <span>{{ store.application.school_level_on_star ? t('form.schoolLevels.' + store.application.school_level_on_start) : '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.vocational_training.title')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.vocational_training_status.title')" class="col-span-full">
                                        <span>{{ store.application.vocational_training_status ? t('form.educationStatus.' + store.application.vocational_training_status) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.vocational_training_started.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.vocational_training_started" :key="index">{{
                                            t('form.vocationalTrainingAreas.' + value) }}</span>
                                        <span v-if="!store.application.vocational_training_started?.length">-</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.vocational_training_will_complete.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.vocational_training_will_complete" :key="index">{{
                                            t('form.vocationalTrainingAreas.' + value) }}</span>
                                        <span v-if="!store.application.vocational_training_will_complete?.length">-</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.vocational_training_comment.title')">
                                        <span>{{ store.application.vocational_training_comment || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.vocational_training_certified_date.title')">
                                        <span>{{ store.application.vocational_training_certified_date ? getFormattedDate(store.application.vocational_training_certified_date) : '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.studies')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.studies_status.title')" class="col-span-full">
                                        <span>{{ store.application.studies_status ? t('form.educationStatus.' + store.application.studies_status) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.study_started_title.title')">
                                        <span>{{ store.application.study_started_title || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.study_title.title')">
                                        <span>{{ store.application.study_title || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.work_life_experience')" class="table-print" columns="2">
                                <FormFieldResult :label="t('form.msOfficeKnowledge.title')">
                                        <span> {{ store.application.ms_office_knowledge ? t('form.msOfficeKnowledge.' +
                                            store.application.ms_office_knowledge) : '-' }} </span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.other_knowledge.title')">
                                        <span> {{ store.application.other_knowledge || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.military_experience.title')">
                                        <span>{{ store.application.military_experience ? t('form.yesOrNo.' +
                                            store.application.military_experience) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.military_remarks.title')">
                                        <span> {{ store.application.military_remarks || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.international_experience_longer_than_3_months.title')">
                                        <span>{{ t('form.yesOrNo.' +
                                            store.application.international_experience_longer_than_3_months)
                                        }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.international_experience_comment.title')">
                                        <span>{{ store.application.international_experience_comment || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.volunteerExperience.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.volunteer_experience" :key="index">{{
                                            t('form.volunteerExperience.' + value) }}</span>
                                        <span v-if="!store.application.volunteer_experience?.length">-</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.volunteer_experience_other_remarks.title')">
                                        <span>{{ store.application.volunteer_experience_other_remarks || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.lived_alone_shared_apartment.title')">
                                        <span>{{ t('form.yesOrNo.' + store.application.lived_alone_shared_apartment) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.leadershipResponsibilities.title')">
                                        <span>{{ store.application.leadership_responsibilities ?
                                            t('form.leadershipResponsibilities.' +
                                                store.application.leadership_responsibilities) : '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.driving')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.driverLicense.title')">
                                        <span>{{ t('form.driverLicense.' + store.application.driver_license) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.driverLicenseClasses.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.driver_license_class" :key="index">{{
                                            t('form.driverLicenseClasses.' + value) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.driver_license_since.title')">
                                        <span>{{ store.application.driver_license_since ? getFormattedDate(store.application.driver_license_since) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.driver_license_comment.title')">
                                        <span>{{ store.application.driver_license_comment || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.communication')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.preferredCommunicationLanguages.title')">
                                        <span>{{ t('form.preferredCommunicationLanguages.' +
                                            store.application.preferred_communication_language) }}</span>
                                    </FormFieldResult>

                                    <div></div>

                                    <FormFieldResult :label="t('form.norwegian_language_level.title')">
                                        <span>{{ t('form.languageLevels.' + store.application.norwegian_language_level)
                                        }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.german_language_level.title')">
                                        <span>{{ t('form.languageLevels.' + store.application.german_language_level) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.english_language_level.title')">
                                        <span>{{ t('form.languageLevels.' + store.application.english_language_level) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.languages.title')" class="dropdown-multiple">
                                        <span v-for="(value, index) in store.application.other_languages" :key="index">{{
                                            t('form.languages.' + value) }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.emergency_contact')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.relative_name.title')">
                                        <span>{{ store.application.relative_name || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.relative_email_address.title')">
                                        <span>{{ store.application.relative_email_address || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.relative_address.title')">
                                        <span>{{ store.application.relative_address || '-' }}</span>
                                    </FormFieldResult>

                                    <div></div>

                                    <FormFieldResult :label="t('form.relative_country_code.title')">
                                        <span>{{ store.application.relative_country_code || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.relative_phone_number.title')">
                                        <span>{{ store.application.relative_phone_number || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>

                                <FormSection :title="t('form.health')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.healthy.title')">
                                        <span>{{ t('form.yesOrNo.' + store.application.healthy) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.can_work_full_time.title')">
                                        <span>{{ t('form.yesOrNo.' + store.application.can_work_full_time) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.health_information.title')">
                                        <span>{{ store.application.health_information || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.food_intolerance.title')">
                                        <span>{{ store.application.food_intolerance || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>                            

                                <FormSection :title="t('form.travel_info.title')" class="table-print" columns="2">
                                    <FormFieldResult :label="t('form.primary_citizenship')">
                                        <span>{{ t('form.citizenship.' + store.application.primary_citizenship) }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_id_card.title')" :data-doc="document = getDocument('passport_id_card')">
                                        <div v-if="document" class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                                            <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                                            <div class="flex flex-col">
                                                <a :href="directus.url.origin + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                                <span class="text-xs">{{ document.size }}</span>
                                            </div>
                                            <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                                        </div>
                                        <label v-show="!document" for="passport_id_card">-</label>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_first_name.title')">
                                        <span>{{ store.application.passport_first_name || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_last_name.title')">
                                        <span>{{ store.application.passport_last_name || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_issue_date.title')">
                                        <span>{{ store.application.passport_issue_date ?
                                            getFormattedDate(store.application.passport_issue_date) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_issue_place.title')">
                                        <span>{{ store.application.passport_issue_place || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_valability.title')">
                                        <span>{{ store.application.passport_valability ?
                                            getFormattedDate(store.application.passport_valability) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.passport_number.title')">
                                        <span>{{ store.application.passport_number || '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.secondary_citizenship')">
                                        <span>{{ store.application.secondary_citizenship ? t('form.citizenship.' + store.application.secondary_citizenship) : '-' }}</span>
                                    </FormFieldResult>

                                    <FormFieldResult :label="t('form.norwegian_personal_number.title')">
                                        <span>{{ store.application.norwegian_personal_number || '-' }}</span>
                                    </FormFieldResult>
                                </FormSection>
                            </div>
                        </div>

                        <form @submit.prevent="updateDocuments" class="p-4 grid gap-4 print:hidden">
                            <FormSection :title="t('form.documents')" columns="2">
                                <BccInput :label="t('form.profile_picture.title')" type="file" id="profile_picture"
                                    name="profile_picture" class="col-span-full required">
                                    <span>{{ $t('form.profile_picture.tooltip') }}</span>
                                </BccInput>

                                <BccInput :label="t('form.cv.title')" type="file" id="cv" name="cv" />

                                <BccInput :label="t('form.european_health_insurance_card.title')" type="file"
                                    id="european_health_insurance_card" name="european_health_insurance_card">
                                    {{ t('form.european_health_insurance_card.tooltip') }}
                                </BccInput>

                                <BccInput :label="t('form.driver_license_copy.title')" type="file" id="driver_license_copy"
                                    name="driver_license_copy" />

                                <BccInput :label="t('form.certificates.title')" type="file" id="certificates"
                                    name="certificates" />

                                <BccInput :label="t('form.work_certificates.title')" type="file" id="work_certificates"
                                    name="work_certificates" />

                                <FormFieldResult :label="t('form.portfolio.title')">
                                    <span><a :href="store.application.portfolio || '#'" target="_blank">{{
                                        store.application.portfolio || '-' }}</a></span>
                                </FormFieldResult>

                                <BccInput :label="t('form.social_security_card.title')" type="file"
                                    id="social_security_card" name="social_security_card">
                                    {{ t('form.social_security_card.tooltip') }}
                                </BccInput>


                                <BccInput :label="t('form.criminal_record_check.title')" type="file"
                                    id="criminal_record_check" name="criminal_record_check" />

                                <div class="col-span-full" v-if="store.updatedDocuments.length">
                                    <p
                                        class="bg-alert-50 px-4 py-2 text-alert-200 inline-flex border border-alert-100 rounded">
                                        <span class="material-symbols-rounded mr-2">sms_failed</span> {{
                                            t('form.confirmNewDocuments') }}
                                    </p>
                                </div>

                                <BccInput :label="t('form.tax_id')" v-model="store.tax_id" />

                                <FormFieldResult :label="t('form.additional_comment')" class="col-span-full">
                                    <span class="whitespace-pre-wrap">{{ store.application.additional_comment || '-' }}</span>
                                </FormFieldResult>

                                <BccAlert context="danger" icon v-show="store.updateFailed" class="col-span-full">{{ t('form.errors.applicationUpdateFailed') }}</BccAlert>
                            </FormSection>

                            <BccButton variant="primary" class="mr-auto"
                                :disabled="store.isLoading || !store.updatedDocuments.length && !store.tax_id"
                                :class="{ 'cursor-not-allowed': store.isLoading || !store.updatedDocuments.length && !store.tax_id }"
                                type="submit">
                                <div class="spinner" :class="{ loading: store.isLoading }"></div>
                                {{ t('form.save') }}
                            </BccButton>
                        </form>
                    </template>
                </BccTabs>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, computed, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { readItems, updateItem, deleteItem } from '@directus/sdk';
import { readUsers } from '@directus/sdk';
import { useAuthStore } from '../stores/auth'
import { useRoute, useRouter } from 'vue-router'
import { getFileSize, getFileExtension, getFileName } from '../helpers/files.js'
import { getFormattedDate, amountFormatter } from '../helpers/fields.js'
import { KeyboardArrowLeftIcon, PersonIcon, ChatBubbleIcon, ReportIcon, CheckCircleIcon, CancelIcon, CreditCardIcon, RadioButtonPartialIcon, MoreHorizIcon, CalendarMonthIcon } from '@bcc-code/icons-vue'
import { BccButton, BccInput, BccCheckbox, BccRadio, BccTable, BccBadge, BccTabs, BccStatusIndicator, BccAlert } from '@bcc-code/design-library-vue'

import FormSection from '../components/FormSection'
import FormFieldResult from '../components/FormFieldResult'
import TabsWrapper from '../components/TabsWrapper.vue'
import Tab from '../components/Tab.vue'

const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

const store = reactive({
    access_token: null,
    application: null,
    employments: [],
    incidents: [],
    communications: [],
    newCommunication: null,
    updatedDocuments: [],
    files: [],
    tax_id: "",
    updateFailed: false,
    isLoading: false,
    sortBy: 'display.date',
    sortDirection: 'descending',
    sortByForEmployments: 'display.arrival_date',
    sortDirectionForEmployments: 'ascending',
})

const employmentsColumns = () => [
    {
        text: t('dashboard.location'),
        key: 'display.location'
    },
    {
        text: t('dashboard.arrival_date'),
        key: 'display.arrival_date'
    },
    {
        text: t('dashboard.departure_date'),
        key: 'display.departure_date'
    },
    {
        text: t('dashboard.current_employer'),
        key: 'display.employer'
    },
    {
        text: t('dashboard.employment_start_date'),
        key: 'display.start_date'
    },
    {
        text: t('dashboard.employment_end_date'),
        key: 'display.end_date'
    },
    {
        text: t('dashboard.actions.title'),
        key: 'actions',
        sortable: false
    }
]

const columns = () => [
  {
    text: t('dashboard.date'),
    key: 'display.date'
  },
  {
    text: t('incident.type.title'),
    key: 'display.type'
  },
  {
    text: t('incident.people_involved.title'),
    key: 'display.people_involved',
    sortable: false
  },
  {
    text: t('dashboard.actions.title'),
    key: 'actions',
    sortable: false
  }
]

const router = useRouter()
const route = useRoute()
const directusStore = useDirectusStore()
const directus = directusStore.directus
const user = directusStore.user
const auth = useAuthStore()

onBeforeMount(async () => {
    store.access_token = await directus.getToken()

    setEmptyCommunication()
    await getApplication()
    // await getIncidents()
    // await getCommunication()
    // await getStatus()

    setDocumentsListener()
})

function transformApplicationsData(data) {
  return data.map(item => {
    const display = {}

      display.date = getFormattedDate(item.date)
      display.type = item.type
      display.people_involved = `${item.people_involved.length} ${item.people_involved.length ? t('dashboard.participant').toLowerCase() : t('dashboard.participants').toLowerCase()}`

      return { ...item, display }
    })
}

function transformEmploymentsData(data) {
    if (!data) return []

    return data.map(item => {
        const display = {}

        display.location = item.location?.name
        display.arrival_date = item.arrival_date
        display.departure_date = item.departure_date
        display.employer = item.employer_id?.legal_entity_name
        display.start_date = item.date_start
        display.end_date = item.date_end

        return { ...item, display }
    })
}

function setDocumentsListener() {
    document.querySelectorAll('input[type="file"]').forEach(i => {
        i.addEventListener("change", setFileContentInData)
    })
}

function setEmptyCommunication() {
    store.newCommunication = {
        id: null,
        message: ""
    }
}

async function getApplication() {
    const user = await directus.request(
        readUsers({
            filter: {
                id: { _eq: route.params.id }
            },
            fields: ['*', 'applications.created_by.id', 'applications.created_by.person_id', 'employments.*', 'employments.location.name', 'employments.employer_id.legal_entity_name', 'applications.*', 'applications.local_church.*', 'applications.profile_picture.*.*', 'applications.cv.*', 'applications.certificates.*', 'applications.work_certificates.*', 'applications.social_security_card.*', 'applications.passport_id_card.*', 'applications.european_health_insurance_card.*', 'applications.driver_license_copy.*', 'applications.criminal_record_check.*']
        })
    )
    if (!user.length) return

    store.application = user[0].applications.pop()
    store.tax_id = store.application.tax_id
    store.employments = transformEmploymentsData(user[0].employments)

    setInitialDocuments()
}

const sortedEmployments = computed(() => {
  return store.employments
    // .filter(a => {
    //   if (!store.employer_id) return true
    //   if (!a.potential_placement) return false

    //   const tags = user.employments
    //     .filter(e => e.employer_id.id == store.employer_id)
    //     .map(e => e.employer_id.tags)
    //     .flat()

    //   return a.potential_placement.some(p => {
    //     return tags.includes(p)
    //   })
    // })
    // .filter(a => {
    //   if (!a.potential_placement) return false

    //   return store.search_potentialPlacement
    //     ? a.potential_placement.includes(store.search_potentialPlacement) : true
    // })
    // .filter(a => {
    //   return (a.first_name + ' ' + a.last_name).toLowerCase().includes(store.search_name.toLowerCase())
    //     || (a.last_name + ' ' + a.first_name).toLowerCase().includes(store.search_name.toLowerCase())
    // })
})

async function getIncidents() {
    const incidents = await directus.request(
        readItems('incidents', {
            filter: {
                people_involved: {
                    directus_users_id: {
                        _in: store.application.created_by.id
                    }
                }
            },
            limit: -1
        })
    )
    store.incidents = transformApplicationsData(incidents)
}

async function getCommunication() {
    const communications = await directus.request(
        readItems('communications', {
            filter: {
                referenced_user: { _eq: route.params.id },
                access: { _eq: "stedsansvarlige" }
            },
            fields: ['*', 'user_created.id', 'user_created.first_name', 'user_created.last_name'],
            limit: -1
        })
    )
    store.communications = communications
}

async function saveCommunication() {
    store.updateFailed = false
    store.isLoading = true

    try {
        if (!store.newCommunication.id) {
            let communication = await directus.request(
                readItems('communications', {
                    referenced_user: route.params.id,
                    access: "stedsansvarlige",
                    message: store.newCommunication.message
                })
            )

            communication.user_created = {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name
            }
            store.communications.push(communication)
        }
        else {
            await directus.request(updateItem('communications', store.newCommunication.id, {
                message: store.newCommunication.message
            }))
        }

        store.isLoading = false
        setEmptyCommunication()
    }
    catch (error) {
        store.updateFailed = true
        store.isLoading = false
        console.error("There was an error on adding/editing!", error)
    }
}

function editCommunication(communication) {
    store.newCommunication = communication
}

async function deleteCommunication(communication) {
    try {
        await directus.request(deleteItem('communications', communication.id))
        store.communications = store.communications.filter(c => c.id != communication.id)
    }
    catch (error) {
        console.error("There was an error on deleting!", error)
    }
}

function setInitialDocuments() {
    const documents = ['cv', 'profile_picture', 'passport_id_card', 'european_health_insurance_card', 'driver_license_copy', 'certificates', 'work_certificates', 'social_security_card', 'criminal_record_check']

    documents.filter(fieldName => store.application[fieldName]).forEach(fieldName => {
        store.files.push({
            fieldName: fieldName,
            tempName: store.application[fieldName].filename_download,
            ext: getFileExtension(store.application[fieldName].filename_download),
            size: getFileSize(store.application[fieldName].filesize),
            assetId: store.application[fieldName].id
        })
    })
}

function setFileContentInData(event) {
    const data = event.target.files[0]
    const id = event.target.getAttribute('id')
    store.files.push({
        fieldName: id,
        data: data,
        tempName: data.name,
        ext: getFileExtension(data.name),
        size: getFileSize(data.size)
    })

    setUpdatedDocuments(id)
}

function setUpdatedDocuments(id) {
    store.updatedDocuments.push(id)
    store.updatedDocuments = [...new Set(store.updatedDocuments)]
}

function cancelFileUpload(event) {
    const id = event.target.parentElement.dataset.id
    document.querySelector('input#' + id).value = ''
    store.files = store.files.filter(f => f.fieldName != id)

    setUpdatedDocuments(id)
}

async function updateDocuments() {
    store.updateFailed = false
    store.isLoading = true

    try {
        await uploadFiles()

        let data = {
            tax_id: store.tax_id
        }

        store.updatedDocuments.forEach(id => {
            const file = store.files.find(f => f.fieldName == id)
            data[id] = file ? file.assetId : null
        })

        await directus.request(updateItem('applications', store.application.id, data))

        store.isLoading = false
        location.reload()
    }
    catch (error) {
        store.updateFailed = true
        store.isLoading = false
        console.error("There was an error!", error)
    }
}

async function uploadFiles() {
    await Promise.all(store.updatedDocuments.map(async (id) => {
        const file = store.files.find(f => f.fieldName == id)

        if (file) {
            const fileName = auth.userData['name'] + ' - ' + getFileName(id)

            const form = new FormData()
            form.append('title', fileName)
            form.append('application_id', store.application.id)
            form.append('file', file.data)

            const uploadedFile = await directus.request(uploadFiles(form))
            file.assetId = uploadedFile.id
        }
    }))
}

function getEmployerEvaluation() {
    return store.application.interests.find(i => i.employer_id.id == route.params.employer_id)
}

function getEmployerName() {
    return getEmployerEvaluation()?.employer_id.legal_entity_name
}

function getDocument(id) {
    return store.files.find(f => f.fieldName == id)
}

function goToIncident(incident) {
    router.push({ path: '/incident/' + incident.id })
}

async function getStatus() {
    const statuses = await directus.request(() => ({
        path: '/stripe-checkout/status',
        method: 'GET'
    }))
    store.status = statuses.find(a => a.person_id == store.application.created_by.person_id)
}

function editEmployment(employment) {
    router.push({ path: '/employment/' + employment.id })
}

</script>

<style scoped>
@media print {
    .hide-bg-print {
        background-color: transparent;
    }
}
</style>
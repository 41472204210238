<template>
    <div v-if="data && data.length > 0" class="w-[275px] md:w-[395px]">
        <Ranking :ranking="data" />
    </div>
    <div v-else-if="loading">Loading ...</div>
    <div v-else class="text-red-500 p-4">
        No data available
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useDirectusStore } from '../../stores/directus'
import Ranking from './Ranking.vue';

const directusStore = useDirectusStore()
const directus = directusStore.directus

const data = ref([])
const loading = ref(true);

onMounted(async () => {
    data.value = await directus.request(() => ({
        path: '/aksjon/team',
        method: 'GET',
    }))
    loading.value = false;
})
</script>